import React, { forwardRef } from "react";
import styled from "styled-components";
import { CaretDown } from "shared/assets/svgs";

const Select = forwardRef(
  (
    {
      name,
      defaultValue,
      onChange,
      children,
      bg = "#FFFFFF",
      padding,
      border,
      width = "100%",
      margin,
      className,
      height,
      fontSizes,
      color,
      borderRadius = "10px",
      value,
      id,
      style,
      disabled,
      outline,
    },
    ref
  ) => {
    return (
      <>
        <StyledSelect
          name={name}
          padding={padding}
          defaultValue={defaultValue}
          onChange={onChange}
          bg={bg}
          border={border}
          width={width}
          margin={margin}
          className={className}
          height={height}
          fontSizes={fontSizes}
          color={color}
          borderRadius={borderRadius}
          ref={ref}
          value={value}
          id={id}
          style={style}
          disabled={disabled}
          outline={outline}
        >
          {children}
        </StyledSelect>
        <CaretDown />
      </>
    );
  }
);

const StyledSelect = styled.select`
  display: block;

  width: ${(props) => props.width};
  height: ${(props) => props.height};

  font-size: ${(props) => props.fontSizes};
  color: ${(props) => props.color};

  border: ${(props) => props.border};
  border-radius: ${(props) => props.borderRadius};

  padding: ${(props) => props.padding};

  background-color: ${(props) => props.bg};

  margin: ${(props) => props.margin};
  outline: ${(props) => props.outline};

  &:disabled {
    cursor: not-allowed;
    opacity: 0.75;
    background: #dcd7d7;
  }
`;

export { Select };
