import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { Button, Error, Input, Modal } from "UI/atoms";
import checkIcon from "shared/images/check2.svg";
import { validatorMeasureUnits } from "../../utils/validatorSchama";
import { useAddItem } from "pages/Settings/queries/settings";
import Close from "shared/images/xcircle.svg";
import { useEffect } from "react";

const MeasureUnits = ({
  isOpen,
  toggleModal,
  onSuccesToast,
  closeModal,
  title,
  addItem,
  itemToEdit,
  editItem,
  setSelectItemId,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      name_singular: "",
      name_plural: "",
      abbreviation: "",
    },
    resolver: yupResolver(validatorMeasureUnits),
  });

  const messageErrors = {
    name_singular: "El nombre en singular ya existe",
    name_plural: "El nombre en plural ya existe",
    abbreviation: "La abreviatura ingresada ya existe",
  };

  const addOrEditItem = (e, action) => {
    e.preventDefault();

    switch (action) {
      case "add":
        handleSubmit((data) => {
          addItem(data, {
            onError: (err) => {
              const errorsInputs = JSON.parse(err.request.response);
              const newArray = Object.keys(errorsInputs).map((key) => {
                return {
                  type: key,
                  value: errorsInputs[key],
                  message: messageErrors[key],
                };
              });
              newArray?.map((err) =>
                setError(`${err.type}`, { message: `${err.message}` })
              );
            },
            onSuccess: () => {
              onSuccesToast("success", "Medida agregada con éxito");
              setSelectItemId("");
              reset();
            },
          });
        })();
        break;

      case "edit":
        handleSubmit((data) => {
          editItem(data, {
            onError: (err) => {
              const errorsInputs = JSON.parse(err.request.response);
              const newArray = Object.keys(errorsInputs).map((key) => {
                return {
                  type: key,
                  value: errorsInputs[key],
                  message: messageErrors[key],
                };
              });
              newArray?.map((err) =>
                setError(`${err.type}`, { message: `${err.message}` })
              );
            },
            onSuccess: () => {
              onSuccesToast("success", "Medida editada con éxito");
              setSelectItemId("");
              reset();
            },
          });
        })();
        break;
      default:
        console.log("Default");
    }
  };

  useEffect(() => {
    if (itemToEdit) {
      setValue("name_singular", itemToEdit?.name_singular);
      setValue("name_plural", itemToEdit?.name_plural);
      setValue("abbreviation", itemToEdit?.abbreviation);
    }
    if (!isOpen) {
      clearErrors();
    }
  }, [itemToEdit, setValue, isOpen, clearErrors]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggleModal={toggleModal}
        width="600px"
        padding="34px 38px"
        className="modal-ajustes"
      >
        <div className="modal-head">
          <h1>{title}</h1>
          <Button width="40px" height="40px">
            <img src={Close} onClick={closeModal} alt="Cerrar modal"/>
          </Button>
        </div>
        <form
          className="ajustes-fields"
          onSubmit={(e) =>
            addOrEditItem(e, itemToEdit.length === 0 ? "add" : "edit")
          }
        >
          <div className="row">
            <div className="field">
              <label htmlFor="name_singular">Nombre singular</label>
              <Input
                placeholder="Nombre singular"
                type="text"
                name="name_singular"
                id="name_singular"
                {...register("name_singular")}
                border="1px solid #908892"
              />
            </div>
            <Error>{errors?.name_singular?.message}</Error>
          </div>
          <div className="row">
            <div className="field">
              <label htmlFor="name_plural">Nombre plural</label>
              <Input
                placeholder="Nombre plural"
                type="text"
                name="name_plural"
                id="name_plural"
                {...register("name_plural")}
                border="1px solid #908892"
              />
            </div>
            <Error>{errors?.name_plural?.message}</Error>
          </div>

          <div className="row">
            <div className="field">
              <label htmlFor="abbreviation">Abreviatura</label>
              <Input
                placeholder="Abreviatura"
                type="text"
                name="abbreviation"
                id="abbreviation"
                {...register("abbreviation")}
                border="1px solid #908892"
                maxLength="8"
              />
            </div>
            <Error>{errors?.abbreviation?.message}</Error>
          </div>

          <div className="actions">
            <Button
              background="#413A44"
              color="white"
              width="140px"
              height="44px"
              fontSize="1.4em"
              className="action-btn"
              fontWeight="400"
            >
              <img src={checkIcon} width="18" alt="Guardar cambios" />
              Confirmar
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default MeasureUnits;
