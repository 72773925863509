import { useNavigate } from "react-router-dom";
import eyeIcon from "shared/images/black-eye.svg";
import { Button } from "UI/atoms";
import { isValidObject } from "utils/isValidObject";
import { formattedDateAndTime } from "utils/formattedDateAndTime";

const DeliveryRow = ({ delivery }) => {
  const navigate = useNavigate();

  return (
    <tbody>
      <tr>
        <td>{formattedDateAndTime(delivery.created_at).time}</td>
        <td>{formattedDateAndTime(delivery.created_at).date}</td>
        <td>{delivery.zero_padded_display_id}</td>
        <td>{delivery.association_name}</td>
        <td>
          <Button
            background="transparent"
            color="#413A44"
            width="auto"
            height="auto"
            fontSize="0.9rem"
            className="action-btn"
            onClick={() => navigate(`${delivery.id}/detail`)}
          >
            <img src={eyeIcon} width="24" alt="Ver detalles" />
          </Button>
        </td>
      </tr>
    </tbody>
  );
};

export { DeliveryRow };
