import React from "react";
import { ThemeProvider } from "styled-components";
import { theme } from "utils/theme";
import GlobalStyles from "utils/globalStyles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Routes, Route } from "react-router-dom";
import { Login } from "pages";
import Products from "pages/Settings/Products";
import Organization from "pages/Settings/Organization";
import ReportsPage from "pages/Reports";
import ReportsList from "pages/Reports/ReportsLIst";
import { useNotifierFactory } from "hooks/useNotifierFactory";
import HeaderTabs from "pages/Products/HeaderTabs";
import DeliveriesPage from "pages/Deliveries";
import DeliveriesList from "pages/Deliveries/DeliveriesList";
import DeliveryDetail from "pages/Deliveries/DeliveryDetail";

const PricesListLayout = React.lazy(() =>
  import(/* webpackPrefetch: true */ "pages/PricesListLayout")
);

const PricesList = React.lazy(() =>
  import(/* webpackPrefetch: true */ "pages/PricesListLayout/PricesList")
);

const NewPriceList = React.lazy(() =>
  import(/* webpackPrefetch: true */ "pages/PricesListLayout/NewPriceList")
);

const EditPriceList = React.lazy(() =>
  import(/* webpackPrefetch: true */ "pages/PricesListLayout/EditPriceList")
);

const List = React.lazy(() =>
  import(/* webpackPrefetch: true */ "pages/Coordinators/List")
);
const Groups = React.lazy(() =>
  import(/* webpackPrefetch: true */ "pages/Coordinators/Groups")
);
const Orders = React.lazy(() =>
  import(/* webpackPrefetch: true */ "pages/Orders")
);
const OrdersList = React.lazy(() =>
  import(/* webpackPrefetch: true */ "pages/Orders/OrdersList")
);

const AddOrEditOrder = React.lazy(() =>
  import(/* webpackPrefetch: true */ "pages/Orders/AddOrEditOrder")
);
const OrderDetail = React.lazy(() =>
  import(/* webpackPrefetch: true */ "pages/Orders/Detail")
);

const Artisan = React.lazy(() =>
  import(/* webpackPrefetch: true */ "pages/Artisans")
);
const ArtisanList = React.lazy(() =>
  import(/* webpackPrefetch: true */ "pages/Artisans/ArtisansList")
);

const AddOrEditArtisan = React.lazy(() =>
  import(/* webpackPrefetch: true */ "pages/Artisans/AddOrEditArtisan")
);

const GroupPage = React.lazy(() =>
  import(/* webpackPrefetch: true */ "pages/Groups")
);
const GroupList = React.lazy(() =>
  import(/* webpackPrefetch: true */ "pages/Groups/GroupList")
);

const AddOrEditGroup = React.lazy(() =>
  import(/* webpackPrefetch: true */ "pages/Groups/AddOrEditGroup")
);

const ProductsPage = React.lazy(() =>
  import(/* webpackPrefetch: true */ "pages/Products")
);

const AddOrEditProduct = React.lazy(() =>
  import(/* webpackPrefetch: true */ "pages/Products/AddOrEditProduct")
);

const ProtectedApp = React.lazy(() =>
  /*  */
  import(/* webpackPrefetch: true */ "protected-app")
);

const SettingsPage = React.lazy(() =>
  import(/* webpackPrefetch: true */ "pages/Settings")
);

const StockPage = React.lazy(() =>
  import(/* webpackPrefetch: true */ "pages/Stock")
);

const WarehousesList = React.lazy(() =>
  import(/* webpackPrefetch: true */ "pages/Stock/WarehousesList")
);
const WarehouseDetail = React.lazy(() =>
  import(
    /* weDepósito origenbpackPrefetch: true */ "pages/Stock/WarehouseDetail"
  )
);

const ProductDescription = React.lazy(() =>
  import(
    /* weDepósito origenbpackPrefetch: true */ "pages/Products/ProductsArtisans/Product"
  )
);

const WarehouseTransfer = React.lazy(() =>
  import(
    /* weDepósito origenbpackPrefetch: true */ "pages/Stock/WarehouseDetail/WarehouseTransfer"
  )
);

const queryClient = new QueryClient();

const queryConfig = {
  queries: {
    refetchOnWindowFocus: false,
    retry: 3,
  },
};

const App = () => {
  const { toaster } = useNotifierFactory();
  return (
    <QueryClientProvider client={queryClient} config={queryConfig}>
      {toaster}
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      <ThemeProvider theme={theme}>
        <GlobalStyles />

        <Routes>
          <Route path="/" element={<ProtectedApp />}>
            <Route path="orders" element={<Orders />}>
              <Route index element={<OrdersList />} />
              <Route path="new-order" element={<AddOrEditOrder />} />
              <Route path=":id/edit-order" element={<AddOrEditOrder />} />
              <Route path=":id/detail" element={<OrderDetail />} />
            </Route>

            <Route path="prices-list" element={<PricesListLayout />}>
              <Route index element={<PricesList />} />
              <Route path="new" element={<NewPriceList />} />
              <Route path=":id/detail" element={<EditPriceList />} />
            </Route>

            <Route path="artisans" element={<Artisan />}>
              <Route index element={<ArtisanList />} />
              <Route path="new-artisan" element={<AddOrEditArtisan />} />
              <Route path=":id/edit-artisan" element={<AddOrEditArtisan />} />
            </Route>

            <Route path="groups" element={<GroupPage />}>
              <Route index element={<GroupList />} />
              <Route path="new-group" element={<AddOrEditGroup />} />
              <Route path=":id/edit-group" element={<AddOrEditGroup />} />
            </Route>

            <Route path="deposits" element={<StockPage />}>
              <Route index element={<WarehousesList />} />
              <Route path="new-deposit" element={<WarehouseDetail />} />
              <Route path=":id/detail" element={<WarehouseDetail />} />
              <Route path=":id/transfer" element={<WarehouseTransfer />} />
            </Route>

            <Route path="products/:name" element={<ProductsPage />}>
              <Route index element={<HeaderTabs />} />
              <Route path="new-product" element={<AddOrEditProduct />} />
              <Route
                path=":productArtisanId/new-product"
                element={<AddOrEditProduct />}
              />
              <Route path=":id/edit-product" element={<AddOrEditProduct />} />

              <Route
                path=":id/description-product"
                element={<ProductDescription />}
              />
            </Route>

            <Route path="reports" element={<ReportsPage />}>
              <Route index element={<ReportsList />} />
            </Route>

            <Route path="deliveries" element={<DeliveriesPage />}>
              <Route index element={<DeliveriesList />} />
              <Route path=":id/detail" element={<DeliveryDetail />} />
            </Route>

            <Route path="settings" element={<SettingsPage />}>
              <Route index element={<Products />} />
              <Route path="organization" element={<Organization />} />
            </Route>
          </Route>

          <Route exact path="/login" element={<Login />}></Route>
        </Routes>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
