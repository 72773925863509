import stockIcon from "shared/images/stock.svg";
import plusIcon from "shared/images/plus.svg";

import { Button } from "UI/atoms/index";
import { useState } from "react";

import { ModalBox } from "pages/Stock/UI/molecules/Box";

const Topbar = ({
  onSetOpenReportModal,
  embedModalComp,
  hasAssociationSelected = true,
}) => {
  return (
    <div className="stock-page-topbar">
      <div className="ptitle">
        <h2>
          <img src={stockIcon} alt="Stock" />
          Reporte de entregas
        </h2>
      </div>
      <ul>
        <li>
          <Button
            background="#339589"
            color="white"
            width="220px"
            height="44px"
            fontSize="1.5em"
            className="action-btn"
            disabled={!hasAssociationSelected}
            onClick={() => onSetOpenReportModal(true)}
          >
            <img src={plusIcon} width="24" alt="Generar nuevo reporte" />
            Generar nuevo reporte
          </Button>
        </li>
      </ul>
      {embedModalComp}
    </div>
  );
};

export { Topbar };
