import { TableHeaders } from "./UI/molecules/TableHeaders";
import { MainInformationRow } from "./UI/molecules/MainInformationRow";
import { DeliveryDetailRow } from "./UI/molecules/DeliveryDetailRow";
import { Topbar } from "./UI/molecules/Topbar";
import { Button } from "UI/atoms";
import backIcon from "shared/images/backWhite.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { DownloadFilesModal } from "./UI/organisms/DownloadFilesModal";
import { useGetDataById } from "shared/queries/commons";
import { Spinner } from "shared/assets/svgs";

const DeliveryDetail = () => {
  const { id } = useParams();
  const [isDownloadFilesModalOpen, setIsOpenDownloadFilesModalOpen] =
    useState(false);
  const navigate = useNavigate();

  const { data: deliveryReport, isLoading: isDeliveryReportLoading } =
    useGetDataById("reports/production-delivery", "production-delivery", id);

  if (isDeliveryReportLoading) return <Spinner />;

  const {
    association_name,
    from_datetime,
    to_datetime,
    zero_padded_display_id,
  } = deliveryReport;

  const mainRowData = {
    association_name,
    from_datetime,
    to_datetime,
    zero_padded_display_id,
  };
  const files = deliveryReport?.files;

  return (
    <>
      <div className="ptitle" style={{ marginBottom: "20px" }}>
        <Button
          background="#413A44"
          color="white"
          width="109px"
          height="44px"
          fontSize="1.5em"
          className="back-btn"
          onClick={() => navigate(-1)}
        >
          <img src={backIcon} alt="Volver atrás" />
          Volver
        </Button>
      </div>
      <section className="ajustes-box">
        <Topbar
          onSetIsOpenDownloadFilesModalOpen={setIsOpenDownloadFilesModalOpen}
          association={association_name}
        />
        <MainInformationRow mainRowData={mainRowData} />
        <div className="entregas-table-container">
          <table className="entregas-table">
            <TableHeaders />
            {deliveryReport.production_deliveries?.map((delivery) => (
              <DeliveryDetailRow key={delivery.id} delivery={delivery} />
            ))}
          </table>
          {/* {!results.result && !isWarehousesLoading ? (
          <NoResultsAdvice message={results.message} />
        ) : null} */}
        </div>
      </section>

      {isDownloadFilesModalOpen ? (
        <DownloadFilesModal
          isOpen={isDownloadFilesModalOpen}
          toggleModal={setIsOpenDownloadFilesModalOpen}
          files={files}
        />
      ) : null}
    </>
  );
};

export default DeliveryDetail;
