import React from 'react'
import {StyledModal} from './styles'

const Modal = ({children, isOpen, toggleModal, width='100%', height, background='#fff', justifyCont='center', alignItems='center', style, display, padding, borderRadius, className}) => {
  return (
    <StyledModal
      isOpen={isOpen}
      onBackgroundClick={() => toggleModal(false)}
      onEscapeKeydown={() => toggleModal(false)}
      width={width}
      height={height}
      padding={padding}
      background={background}
      display={display}
      justifyCont={justifyCont}
      alignItems={alignItems}
      style={style}
      borderRadius={borderRadius}
      className={className}
    >
      {children}
    </StyledModal>
  )
}
export {Modal}