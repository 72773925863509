import React from "react";
import styled from "styled-components";

const Button = ({
  children,
  background,
  border = "none",
  fontSize,
  type,
  fontWeight = "600",
  style,
  color,
  padding,
  className,
  width = "100%",
  height,
  borderRadius = "10px",
  disabled,
  onClick,
  onSubmit,
  borderBottom,
  margin,
}) => {
  return (
    <StyledButton
      onClick={onClick}
      onSubmit={onSubmit}
      background={background}
      fontSize={fontSize}
      style={style}
      color={color}
      fontWeight={fontWeight}
      type={type}
      borderRadius={borderRadius}
      padding={padding}
      className={className}
      height={height}
      width={width}
      border={border}
      disabled={disabled}
      borderBottom={borderBottom}
      margin={margin}
    >
      {children}
    </StyledButton>
  );
};

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: ${(props) => props.padding};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};

  color: ${(props) => props.color};
  background: ${(props) => props.background};

  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  text-align: center;

  border: ${(props) => props.border};
  border-bottom: ${(props) => props.borderBottom};
  border-radius: ${(props) => props.borderRadius};

  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export { Button };
