const formattedDateAndTime = (date) => {
  const dateToFormat = new Date(date);
  const day = dateToFormat.getDate().toString().padStart(2, "0");
  const month = (dateToFormat.getMonth() + 1).toString().padStart(2, "0");
  const year = dateToFormat.getFullYear().toString();

  const hours = dateToFormat.getHours();
  const minutes = dateToFormat.getMinutes();

  const time =
    hours > 12
      ? `${hours - 12}:${minutes.toString().padStart(2, "0")} PM`
      : `${hours}:${minutes.toString().padStart(2, "0")} AM`;

  return {
    date: `${day}/${month}/${year}`,
    time: time,
  };
};

export { formattedDateAndTime };
