import * as yup from "yup";

const validatorLocation = yup.object().shape({
  name: yup.string().required("El nombre es requerido"),
  description: yup.string(),
  lat: yup.string().nullable(),
  long: yup.string().nullable(),
});

const validatorCommunities = yup.object().shape({
  name: yup.string().required("El nombre es requerido"),
  location: yup.string(),
  referrer: yup.string().required("La referente es requerida"),
});

const validatorAssosiation = yup.object().shape({
  name: yup.string().required("El nombre es requerido"),
  location: yup.string(),
  community: yup.string().required("La comunidad es requerida"),
  warehouse: yup.string().required("El deposito es requerido"),
});
const validatorCustomers = yup.object().shape({
  name: yup.string().required("El nombre es requerido"),
});

export {
  validatorLocation,
  validatorCommunities,
  validatorAssosiation,
  validatorCustomers,
};
