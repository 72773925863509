import React from "react";
import { Spinner } from "shared/assets/svgs";
import { ComarProductRow } from "../ComarProductRow";
import OrderBy from "shared/assets/svgs/OrderBy";

const ProductsComar = ({ filteredProducts, isProductsLoading, onSetHandleSortByCodeProduct }) => {
  return (
    <div className="productos-table-container">
      <table className="productos-table-1">
        <thead>
          <tr>
            <th>
              <div>
                Imagen
              </div>
            </th>
            <th>
            <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                    Código
                    <span onClick={() => {
                      onSetHandleSortByCodeProduct("code")}}>
                         <OrderBy />
                    </span> 
                  </div>
            </th>
            <th>
              <div>Nombre</div>
            </th>
            <th>Material</th>
            <th>Descripción</th>
            <th>Detalles</th>
          </tr>
        </thead>

        <tbody>
          {isProductsLoading ? (
            <tr>
              <th>
                <Spinner />
              </th>
            </tr>
          ) : (
            filteredProducts.map((product) => (
              <ComarProductRow
                product={product}
                isProductsLoading={isProductsLoading}
                key={product?.id}
              />
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ProductsComar;
