import React from "react";
import { Spinner } from "shared/assets/svgs";
import { ArtisanProductRow } from "../ArtisanProductRow";

const ProductsArtisans = ({ filteredProducts, isProductsLoading }) => {
  return (
    <table className="productos-table-1 th">
      <thead>
        <tr>
          <th>Imagen</th>
          <th>Nombre</th>
          <th>Material</th>
          <th>Descripción</th>
          <th>Artesana</th>
          <th>Asociación</th>
          <th>Detalles</th>
        </tr>
      </thead>

      <tbody>
        {isProductsLoading ? (
          <tr>
            <th>
              <Spinner />
            </th>
          </tr>
        ) : (
          filteredProducts?.map((product) => (
            <ArtisanProductRow key={product.id} product={product} />
          ))
        )}
      </tbody>
    </table>
  );
};

export default ProductsArtisans;
