import React from "react";
import { Button, Modal } from "UI/atoms";
import { useDeleteItem } from "../queries/settings";

const ModalDelete = ({
  toggleModal,
  isOpen,
  nameItemToDelete,
  setOpenModalToDelete,
  endpoint,
  idToEditOrDelete,
  onSuccesToast,
  setIdToEditOrDelete,
}) => {
  const { mutate: deleteItemById } = useDeleteItem(endpoint, idToEditOrDelete);

  const itemDelete = (action) => {
    if (action === "aceptar") {
      deleteItemById();
      setOpenModalToDelete(false);
      setIdToEditOrDelete("");
      onSuccesToast(
        "success",
        `Item ${nameItemToDelete} eliminado correctamente`
      );
    }
    if (action === "cancelar") {
      setOpenModalToDelete(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={toggleModal}
      width="600px"
      padding="34px 38px"
      className="modal-ajustes"
    >
      <span className="span-delete">
        Deseas eliminar el item{" "}
        <span className="delete-bold">{nameItemToDelete}</span> ?
      </span>
      <div className="div-delete">
        <Button onClick={() => itemDelete("aceptar")}>Eliminar</Button>
        <Button onClick={() => itemDelete("cancelar")}>Cancelar</Button>
      </div>
    </Modal>
  );
};

export default ModalDelete;
