import * as React from "react";
import * as auth from "./provider";
import { useAsync } from "hooks/useAsync";
import { Spinner } from "shared/assets/svgs/index";
import { client } from "utils/api-client";
import { useNavigate } from "react-router-dom";

import jwtDecode from "jwt-decode";

async function bootstrapAppData() {
  let user = null;

  const token = auth.getRefreshToken();
  if (token) {
    try {
      const data = await client("token/refresh/", {
        data: {
          refresh: token,
        },
      });
      user = data;
    } catch (e) {
      console.log(e);
    }
  }

  return user;
}

const AuthContext = React.createContext();
AuthContext.displayName = "AuthContext";

const AuthProvider = (props) => {
  const navigate = useNavigate();
  const {
    data: user,
    status,
    isLoading,
    isIdle,
    isSuccess,
    error,
    run,
    setData,
  } = useAsync();
  React.useEffect(() => {
    const appDataPromise = bootstrapAppData();
    run(appDataPromise);
  }, [run]);

  const login = React.useCallback(
    (form) =>
      auth
        .login(form)
        .then((user) =>
          setData(
            !!user.username ? user : jwtDecode(user?.access || user?.refresh)
          )
        ),
    [setData]
  );

  const logout = React.useCallback(() => {
    auth.logout();
    setData(null);
  }, [setData]);

  const value = React.useMemo(
    () => ({ user, login, logout, isSuccess }),
    [login, logout, user]
  );

  if (isLoading || isIdle) {
    return <Spinner />;
  }

  if (isSuccess) {
    return <AuthContext.Provider value={value} {...props} />;
  }
};

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

export { AuthProvider, useAuth };
