import { Modal } from "UI/molecules/index";

import { Button, Error, Input } from "UI/atoms/index";

import Close from "shared/images/xcircle.svg";
import checkIcon from "shared/images/check2.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validatorLocation } from "../utils/validatorSchemas";
import { useEffect } from "react";

const ModalLocation = ({
  isOpenModal,
  setIsOpenModal,
  title,
  addItem,
  onSuccesToast,
  closeModal,
  itemToEdit,
  editItem,
  setSelectItemId,
  deleteItem,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      name: "",
      description: "",
      lat: "",
      long: "",
    },
    resolver: yupResolver(validatorLocation),
  });

  const submitItem = (e, action) => {
    e.preventDefault();

    switch (action) {
      case "add":
        handleSubmit((data) => {
          const newData = { ...data };
          const latitud = newData.lat || null;
          const longitud = newData.long || null;
          const finalData = { ...newData, lat: latitud, long: longitud };

          addItem(finalData, {
            onError: (err) => {
              const error = JSON.parse(err.response.request.responseText);

              if (error) {
                return setError("name", {
                  type: "error",
                  message: "El nombre ingresado ya existe",
                });
              }
            },
            onSuccess: () => {
              onSuccesToast("success", "Item agregado con éxito");
              setSelectItemId("");
              reset();
            },
          });
        })();
        break;
      case "edit":
        handleSubmit((data) => {
          const newData = { ...data };
          const latitud = newData.lat || null;
          const longitud = newData.long || null;
          const finalData = { ...newData, lat: latitud, long: longitud };

          editItem(finalData, {
            onError: (error) => {
              if (error) {
                return setError("name", {
                  type: "error",
                  message: "El nombre ingresado ya existe",
                });
              }
            },
            onSuccess: () => {
              onSuccesToast("success", "Item editado con éxito");
              setSelectItemId("");
              reset();
            },
          });
        })();
        break;

      default:
        console.log("Delete");
        handleSubmit(() => {
          deleteItem();
        });
    }
  };

  useEffect(() => {
    if (itemToEdit) {
      setValue("name", itemToEdit?.name);
      setValue("description", itemToEdit?.description || "");
      setValue("lat", itemToEdit?.lat);
      setValue("long", itemToEdit?.long);
    }
    if (!isOpenModal) {
      clearErrors();
    }
  }, [itemToEdit, setValue, clearErrors, isOpenModal]);

  return (
    <Modal
      isOpen={isOpenModal}
      toggleModal={setIsOpenModal}
      width="600px"
      padding="34px 38px"
      className="modal-ajustes"
    >
      <div className="modal-head">
        <h1>{title}</h1>
        <Button width="40px" height="40px">
          <img src={Close} onClick={closeModal} alt="Cerrar Modal"/>
        </Button>
      </div>

      <form
        className="ajustes-fields"
        onSubmit={(e) =>
          submitItem(e, itemToEdit.length === 0 ? "add" : "edit")
        }
      >
        <div className="row">
          <div className="field">
            <label>Nombre</label>
            <Input
              placeholder="Nombre"
              type="text"
              name="name"
              border="1px solid #908892"
              {...register("name")}
            />
            <Error>{errors.name?.message}</Error>
          </div>
        </div>

        <div className="row">
          <div className="field">
            <label>Descripción</label>
            <textarea
              {...register("description")}
              placeholder="Descripción"
            ></textarea>
          </div>
        </div>

        <div className="row">
          <div className="field">
            <label>Latitud</label>
            <Input
              placeholder="Latitud"
              type="number"
              name="lat"
              border="1px solid #908892"
              {...register("lat")}
            />
          </div>
        </div>

        <div className="row">
          <div className="field">
            <label>Longitud</label>
            <Input
              placeholder="Longitud"
              type="number"
              name="long"
              border="1px solid #908892"
              {...register("long")}
            />
          </div>
        </div>

        <div className="actions">
          <Button
            background="#413A44"
            color="white"
            width="140px"
            height="44px"
            fontSize="1.4em"
            className="action-btn"
            fontWeight="400"
            type="submit"
          >
            <img src={checkIcon} width="18" alt="Guardar cambios" />
            Confirmar
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export { ModalLocation };
