import React from "react";

const CaretRight = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.875 5.4375L19.9375 14.5L10.875 23.5625"
        stroke="#413A44"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        transform="scaleX(-1)"
      />
    </svg>
  );
};

export { CaretRight };
