import React from "react";

const CaretDown = ({ disabled = true }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8275 9L11.5861 16.5L4.34473 9"
        stroke={disabled ? "grey" : "#413A44"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { CaretDown };
