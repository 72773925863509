import * as yup from "yup";

const validatorGroupModal = yup.object().shape({
  name: yup.string().required("El nombre es requerido"),
});

const validatorMeasureTypes = yup.object().shape({
  name: yup.string().required("El nombre es requerido"),
  abbreviation: yup.string().required("La abreviación es requerida"),
});

const validatorMeasureUnits = yup.object().shape({
  name_singular: yup.string().required("El nombre es requerido"),
  name_plural: yup.string().required("El nombre en plural es requerido"),
  abbreviation: yup.string().required("La abreviación es requerida"),
});

export { validatorGroupModal, validatorMeasureTypes, validatorMeasureUnits };
