import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import productosIcon from "shared/images/productos.svg";
import plusIcon from "shared/images/plus.svg";
import { Button, Search, Select } from "UI/atoms/index";
import { useGetProductCharacteristic } from "pages/Products/queries/products";
import { LabeledSelect } from "UI/atoms/LabeledSelect";
import { advicesBuilder } from "utils/advicesBuilder";
import { useGetData } from "shared/queries/commons";
import MagnifityGlass from "shared/assets/svgs/MagnifityGlass";
import { useProductsFilter } from "../utils/hooks/useProductsFilter";
import ProductsComar from "../ProductsComar";
import ProductsArtisans from "../ProductsArtisans";
import useSortHandler from "utils/useSortHandler";

const HeaderTabs = () => {
  const navigate = useNavigate();
  const { data: products, isLoading: isProductsLoading } = useGetData(
    "products",
    "products"
  );

  const { data: productsArtisans } =
    useGetProductCharacteristic("artisan-products");

  const {
    setQuery,
    setCategory,
    setMaterial,
    setShape,
    setTechnique,
    filteredProducts,
  } = useProductsFilter(products, productsArtisans);

  const { data: categories, isLoading: isCategoriesLoading } =
    useGetProductCharacteristic("categories");
  const { data: shapes, isLoading: isShapesLoading } =
    useGetProductCharacteristic("shapes");
  const { data: materials, isLoading: isMaterialsLoading } =
    useGetProductCharacteristic("materials");
  const { data: techniques, isLoading: isTechniquesLoading } =
    useGetProductCharacteristic("techniques");

  const results = advicesBuilder(filteredProducts);

  const { name } = useParams();

  const { handleSortBy, sortedData } = useSortHandler(filteredProducts);
 
  return (
    <>
      <div className="productos-page-topbar">
        <div className="ptitle">
          <h2>
            <img src={productosIcon} alt="Productos" />
            Productos
          </h2>
        </div>

        <ul>
          <li>
            <Button
              background="#339589"
              color="white"
              width="180px"
              height="44px"
              fontSize="1.5em"
              className="action-btn"
              onClick={() => navigate("new-product")}
            >
              <img src={plusIcon} alt="Sumar" width="24" /> Nuevo Producto
            </Button>
          </li>
        </ul>
      </div>

      <section className="container_products_page">
        <section className="action_comar_artisan">
          <Button
            padding={"15px"}
            background={name === "comar" ? "#FFF7F4" : "#FFF7F"}
            borderBottom={
              name === "comar" ? "2px solid #DB7755" : "2px solid #DCD7D7"
            }
            color={name === "comar" ? "#DB7755" : "#413A"}
            width="170px"
            borderRadius="0px"
            fontSize={"15px"}
            onClick={() => navigate("/products/comar")}
          >
            COMAR
          </Button>
          <Button
            padding={"15px"}
            width="170px"
            borderRadius="0px"
            fontSize={"15px"}
            background={name === "artisan" ? "#FFF7F4" : "#FFF7F"}
            borderBottom={
              name === "artisan" ? "2px solid #DB7755" : "2px solid #DCD7D7"
            }
            color={name === "artisan" ? "#DB7755" : "#413A"}
            onClick={() => navigate("/products/artisan")}
          >
            Artesanas
          </Button>
        </section>

        <div className="productos-page-filters">
          <ul>
            <li>
              <div className="productos-page-search">
                <Search
                  placeholder="Buscar por Nombre o Código de producto..."
                  isSearch={true}
                  id="search"
                  name="search"
                  width="500px"
                  height="40px"
                  color="#908892"
                  fontSize="1.2em"
                  onChange={(e) => setQuery(e.target.value)}
                />
                <div className="magnifity">
                  <MagnifityGlass />
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div className="productos-page-categorias">
          <ul>
            {name === "comar" && (
              <LabeledSelect
                label="Categoría"
                isLoading={isCategoriesLoading}
                name="categories"
                onSetterFn={setCategory}
                items={categories}
              />
            )}
            <LabeledSelect
              label="Material"
              isLoading={isMaterialsLoading}
              name="materials"
              onSetterFn={setMaterial}
              items={materials}
            />
            <LabeledSelect
              label="Forma"
              isLoading={isShapesLoading}
              name="shapes"
              onSetterFn={setShape}
              items={shapes}
            />
            <LabeledSelect
              label="Técnica"
              isLoading={isTechniquesLoading}
              name="techniques"
              onSetterFn={setTechnique}
              items={techniques}
            />
          </ul>
        </div>

        {name === "comar" ? (
          <ProductsComar
            filteredProducts={sortedData}
            onSetHandleSortByCodeProduct={handleSortBy}
            isProductsLoading={isProductsLoading}
          />
        ) : (
          <ProductsArtisans
            filteredProducts={filteredProducts}
            isProductsLoading={isProductsLoading}
          />
        )}
      </section>
    </>
  );
};

export default HeaderTabs;
