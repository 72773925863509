import React from "react";
import { formattedDateAndTime } from "utils/formattedDateAndTime";

const MainInformationRow = ({ mainRowData }) => {
  const { from_datetime, to_datetime, zero_padded_display_id } = mainRowData;

  return (
    <div className="main-information-row">
      <div className="main-information-column">
        <p>Desde</p>
        <p>{formattedDateAndTime(from_datetime).date}</p>
      </div>
      <div className="main-information-column">
        <p>Hasta</p>
        <p>{formattedDateAndTime(to_datetime).date}</p>
      </div>
      <div className="main-information-column">
        <p>Hora</p>
        <p>{formattedDateAndTime(from_datetime).time}</p>
      </div>
      <div className="main-information-column">
        <p>Codigo</p>
        <p>{zero_padded_display_id}</p>
      </div>
    </div>
  );
};

export { MainInformationRow };
