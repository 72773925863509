import { Modal } from "UI/molecules/index";

import { Button, Error, Input, Select } from "UI/atoms/index";

import Close from "shared/images/xcircle.svg";
import checkIcon from "shared/images/check2.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validatorCommunities } from "../utils/validatorSchemas";
import { useGetSettings } from "pages/Settings/queries/settings";
import { useEffect } from "react";

const ModalCommunities = ({
  isOpenModal,
  setIsOpenModal,
  title,
  onSuccesToast,
  addItem,
  closeModal,
  itemToEdit,
  editItem,
  setSelectItemId,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setValue
  } = useForm({
    defaultValues:{
      name:"",
      location:"",
      referrer:"",
    },
    resolver: yupResolver(validatorCommunities),
  });

  const { data: artisans } = useGetSettings("artisans");
  const { data: location } = useGetSettings("locations");

  const messageErrors = {
    name: "El nombre ingresado ya existe",
  };

  const submitItem = (e, action) => {
    e.preventDefault();

    switch (action) {
      case "add":
        handleSubmit((data) => {
          addItem(data, {
            onError: (err) => {
              const errorsInputs = JSON.parse(err.request.response);
              const newArray = Object.keys(errorsInputs).map((key) => {
                return {
                  type: key,
                  value: errorsInputs[key],
                  message: messageErrors[key],
                };
              });
              newArray?.map((err) =>
                setError(`${err.type}`, { message: `${err.message}` })
              );
            },
            onSuccess: () => {
              onSuccesToast("success", "Item agregado con éxito");
              setSelectItemId("");
              reset();
            },
          });
        })();
        break;
      case "edit":
        handleSubmit((data) => {
          editItem(data, {
            onError: (err) => {
              const error = JSON.parse(err.response.request.responseText);

              if (error) {
                return setError("name", {
                  type: "error",
                  message: "El nombre ingresado ya existe",
                });
              }
            },
            onSuccess: () => {
              onSuccesToast("success", "Item editado con éxito");
              setSelectItemId("");
              reset();
            },
          });
        })();
        break;
      default:
        console.log("Default");
    }
  };

  useEffect(() => {
    if (itemToEdit) {
      setValue("name", itemToEdit?.name);
      setValue("location", itemToEdit?.location?.id);
      setValue("referrer", itemToEdit?.referrer?.user);
    }
  }, [itemToEdit, setValue]);

  

  return (
    <Modal
      isOpen={isOpenModal}
      toggleModal={setIsOpenModal}
      width="600px"
      padding="34px 38px"
      className="modal-ajustes"
    >
      <div className="modal-head">
        <h1>{title}</h1>
        <Button width="40px" height="40px">
          <img src={Close} onClick={closeModal} alt="Cerrar modal"/>
        </Button>
      </div>

      <form
        className="ajustes-fields"
        onSubmit={(e) =>
          submitItem(e, itemToEdit.length === 0 ? "add" : "edit")
        }
      >
        <div className="row">
          <div className="field">
            <label>Nombre</label>
            <Input
              placeholder="Nombre"
              type="text"
              name="email"
              border="1px solid #908892"
              {...register("name")}
            />
          </div>
          <Error>{errors.name?.message}</Error>
        </div>

        <div className="row">
          <div className="field">
            <label>Ubicación</label>

            <Select
              placeholder="Ubicacion"
              type="text"
              name="email"
              border="1px solid #908892"
              {...register("location")}
            >
              <option value={""}>Selecciona una zona</option>
              {location?.map((loc) => (
                <option value={loc.id}>{loc.name}</option>
              ))}
            </Select>
          </div>
        </div>

        <div className="row">
          <div className="field">
            <label>Referente</label>

            <Select
              placeholder="Ubicacion"
              type="text"
              name="email"
              border="1px solid #908892"
              {...register("referrer")}
            >
              <option value={""}>Selecciona una referente</option>
              {artisans.map((artisan) => (
                <option
                  value={artisan.user}
                >{`${artisan.first_name} ${artisan.last_name}`}</option>
              ))}
            </Select>
          </div>
          <Error>{errors.referrer?.message}</Error>
        </div>

        <div className="actions">
          <Button
            background="#413A44"
            color="white"
            width="140px"
            height="44px"
            fontSize="1.4em"
            className="action-btn"
            fontWeight="400"
          >
            <img src={checkIcon} width="18" alt="Guardar cambios" />
            Confirmar
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export { ModalCommunities };
