import React from "react";
import { Wrapper, EmptyBar, ProgressBar } from "./styles";

const Progress = ({ ...props }) => {
  const { amount, quantity, color, background, showPercentage = true } = props;

  const percentageCompleted = () => {
    if (quantity === 0 && amount === 0) return 0;
    return (amount * 100) / quantity;
  };

  return (
    <Wrapper>
      <div style={{ position: "relative", flexGrow: 1 }}>
        <EmptyBar></EmptyBar>
        <ProgressBar
          color={color}
          background={background}
          width={Math.ceil(percentageCompleted()) + "%"}
        />
      </div>
      {showPercentage && (
        <p style={{ marginLeft: "10px" }}>{Math.ceil(percentageCompleted()) + "%"}</p>
      )}
    </Wrapper>
  );
};

export  {Progress};

