import React from "react";
import { useForm } from "react-hook-form";
import { Button, Error, Input, Modal } from "UI/atoms";
import checkIcon from "shared/images/check2.svg";
import Close from "shared/images/xcircle.svg";
import { yupResolver } from "@hookform/resolvers/yup";
import { validatorCustomers } from "../utils/validatorSchemas";
import { useEffect } from "react";

const ModalCustomers = ({
  isOpenModal,
  setIsOpenModal,
  title,
  closeModal,
  itemToEdit,
  addItem,
  onSuccesToast,
  setSelectItemId,
  editItem,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      name: "",
    },
    resolver: yupResolver(validatorCustomers),
  });

  const submitItem = (e, action) => {
    e.preventDefault();

    switch (action) {
      case "add":
        handleSubmit((data) => {
          addItem(data, {
            onError: (err) => {
              const nameAlreadyexist = JSON.parse(err.request.responseText)
                .name[0];
              if (nameAlreadyexist) {
                setError("name", {
                  type: "model",
                  message: "El nombre ingresado ya existe",
                });
              }
            },
            onSuccess: () => {
              onSuccesToast("success", "Item agregado con exito");
              setSelectItemId("");
              reset();
            },
          });
        })();
        break;

      case "edit":
        handleSubmit((data) => {
            editItem(data, {
              onError: (err) => {
                const nameAlreadyexist = JSON.parse(err.request.responseText)
                  .name[0];
                if (nameAlreadyexist) {
                  setError("name", {
                    type: "model",
                    message: "El nombre ingresado ya existe",
                  });
                }
              },
              onSuccess: () => {
                onSuccesToast("success", "Item editado con exito");
                setSelectItemId("");
                reset();
              },
            });
          })();
        break;
      default:
        console.log("Default");
    }
  };

  useEffect(() => {
    if (itemToEdit?.id) {
      return setValue("name", itemToEdit?.name);
    }
    setValue("name", "");
  }, [itemToEdit, setValue]);

  return (
    <Modal
      isOpen={isOpenModal}
      toggleModal={setIsOpenModal}
      width="600px"
      padding="34px 38px"
      className="modal-ajustes"
    >
      <div className="modal-head">
        <h1>{title}</h1>
        <Button width="40px" height="40px">
          <img src={Close} onClick={closeModal} alt="Cerrar modal"/>
        </Button>
      </div>

      <form
        className="ajustes-fields"
        onSubmit={(e) =>
          submitItem(e, itemToEdit.length === 0 ? "add" : "edit")
        }
      >
        <div className="row">
          <div className="field">
            <label>Nombre</label>
            <Input
              placeholder="Nombre"
              type="text"
              border="1px solid #908892"
              {...register("name")}
              name="name"
            />
          </div>
          <Error>{errors?.name?.message}</Error>
        </div>

        <div className="actions">
          <Button
            background="#413A44"
            color="white"
            width="140px"
            height="44px"
            fontSize="1.4em"
            className="action-btn"
            fontWeight="400"
          >
            <img src={checkIcon} width="18" alt="Guardar cambios" />
            Confirmar
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export { ModalCustomers };
