import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { client } from "utils/api-client";

const errors = [401, 403, 404, 500];

const useGetLookups = () => {
  const { data, isLoading, isError, isSuccess } = useQuery(
    {
      queryKey: ["lookups"],
      queryFn: () => client("lookups/").then((data) => data),

      useErrorBoundary: (error) =>
        errors.includes(parseInt(error.response?.status)),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
    {
      onError: (err) => {
        throw new Error(err);
      },
    }
  );
  return {
    data: data ?? [],
    isLoading,
    isError,
    isSuccess,
  };
};

export { useGetLookups };
