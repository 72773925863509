import { useState } from "react";
import { useNotifierFactory } from "hooks/useNotifierFactory";
import Box from "./BoxProducts";
import MeasureTypes from "./Forms/MeasureType";
import MeasureUnits from "./Forms/MeasureUnits";
import ModalBox from "./Forms/ModalBox";
import {
  useAddItem,
  useEditItem,
  useGetSettings,
  useGetItemById,
} from "../queries/settings";
import ModalDelete from "../ModalDelete";

const Products = () => {
  const { toastType } = useNotifierFactory();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [openModalToDelete, setOpenModalToDelete] = useState(false);

  const [endpoint, setEndpoint] = useState("");
  const [title, setTitle] = useState("");
  const [selectItemId, setSelectItemId] = useState("");
  const [idToEditOrDelete, setIdToEditOrDelete] = useState("");
  const [nameItemToDelete, setNameItemToDelete] = useState("");

  const onSuccesToast = (type, message) => {
    toastType(type, message, 4000);
    setIsOpenModal(false);
  };
  const onErrorToast = (type, message) => {
    toastType(type, message, 4000);
    setIsOpenModal(false);
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };

  const { data: categories, isLoading: loadingCategories } = useGetSettings("categories");
  const { data: shapes, isLoading: loadingShapes } = useGetSettings("shapes");
  const { data: materials, isLoading: loadingMaterials } = useGetSettings("materials");
  const { data: techniques, isLoading: loadingTechniques } = useGetSettings("techniques");
  const { data: measureType, isLoading: loadingMeasureType } = useGetSettings("measure-types");
  const { data: measureUnits, isLoading: loadingMeasureUnits } = useGetSettings("measure-units");
  const { mutate: addItem } = useAddItem(endpoint);
  const { data: itemToEdit } = useGetItemById(endpoint, idToEditOrDelete);
  const { mutate: editItem } = useEditItem(endpoint, idToEditOrDelete);

  const products = [
    {
      param: "categories",
      title: "Categorias",
      box: categories,
      endpoint: "categories",
      loading: loadingCategories,
    },
    {
      param: "shapes",
      title: "Formas",
      box: shapes,
      endpoint: "shapes",
      loading: loadingShapes,
    },
    {
      param: "materials",
      title: "Materiales",
      box: materials,
      endpoint: "materials",
      loading: loadingMaterials,
    },
    {
      param: "techniques",
      title: "Técnicas",
      box: techniques,
      endpoint: "techniques",
      loading: loadingTechniques,
    },
    {
      param: "measure-types",
      title: "Tipos de medida",
      box: measureType,
      loading: loadingMeasureType,
    },
    {
      param: "measure-units",
      title: "Unidades de medida",
      box: measureUnits,
      loading: loadingMeasureUnits,
    },
  ];

  const openModal = (title, endpoint, action) => {
    setIsOpenModal(true);
    setTitle(title);
    setEndpoint(endpoint);

    switch (action) {
      case "add":
        setIdToEditOrDelete("");
        break;
      case "edit":
        setIdToEditOrDelete(selectItemId);
        break;
      default:
        console.log("Default");
    }
  };

  const deleteItem = (endpoint, item) => {
    setOpenModalToDelete(true);
    setEndpoint(endpoint);
    setNameItemToDelete(item?.name);
    setIdToEditOrDelete(item?.id);
  };

  const selectBox = products?.some((obj) => obj.endpoint === endpoint);

  const selectItem = (id) => {
    setSelectItemId(id);
  };

  return (
    <>
      <div className="ajustes">
        <div className="cols">
          {products.map((obj) => (
            <Box
              key={obj.title}
              elements={obj.box}
              title={obj.title}
              endpoint={obj.param}
              openModal={openModal}
              selectItemId={selectItemId}
              selectItem={selectItem}
              deleteItem={deleteItem}
              loadingData={obj.loading}
            />
          ))}
        </div>

        {openModalToDelete && (
          <ModalDelete
            isOpen={openModalToDelete}
            toggleModal={setOpenModalToDelete}
            nameItemToDelete={nameItemToDelete}
            setOpenModalToDelete={setOpenModalToDelete}
            endpoint={endpoint}
            idToEditOrDelete={idToEditOrDelete}
            onSuccesToast={onSuccesToast}
            setIdToEditOrDelete={setIdToEditOrDelete}
            onErrorToast={onErrorToast}
          />
        )}

        {selectBox && (
          <ModalBox
            isOpen={isOpenModal}
            toggleModal={setIsOpenModal}
            onSuccesToast={onSuccesToast}
            closeModal={closeModal}
            onErrorToast={onErrorToast}
            title={title}
            addItem={addItem}
            itemToEdit={itemToEdit}
            editItem={editItem}
            setSelectItemId={setSelectItemId}
          />
        )}

        {title === "Tipos de medida" && (
          <MeasureTypes
            isOpen={isOpenModal}
            toggleModal={setIsOpenModal}
            onSuccesToast={onSuccesToast}
            closeModal={closeModal}
            title={title}
            addItem={addItem}
            itemToEdit={itemToEdit}
            editItem={editItem}
            setSelectItemId={setSelectItemId}
          />
        )}

        {title === "Unidades de medida" && (
          <MeasureUnits
            isOpen={isOpenModal}
            toggleModal={setIsOpenModal}
            onSuccesToast={onSuccesToast}
            closeModal={closeModal}
            title={title}
            addItem={addItem}
            itemToEdit={itemToEdit}
            editItem={editItem}
            setSelectItemId={setSelectItemId}
          />
        )}
      </div>
    </>
  );
};

export default Products;
