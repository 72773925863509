import React from 'react';
import styled from 'styled-components';

const Badge = ({
  children, 
  background='#ccc', 
  fontSize='1.3em', 
  fontWeight='600',
  color, 
  margin, 
  padding, 
  border,
  borderRadius='100%',
  style,
  className,
  width='22px',
  height='22px',
  hasPointer = false,
  onClick
}) => {
  return (
    <StyledBadge
      background={background}
      color={color}
      padding={padding}
      margin={margin} 
      border={border}
      borderRadius={borderRadius}
      fontSize={fontSize}
      fontWeight={fontWeight}
      style={style}
      width={width}
      height={height}
      className={className}
      onClick={onClick}
    >
      {children}
    </StyledBadge>
  )
}

const StyledBadge = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  width: ${props => props.width};
  height: ${props => props.height};

  padding: ${props => props.padding};
  margin: ${props => props.margin};

  color: ${props => props.color};
  background: ${props => props.background};

  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  text-align: center;

  border: ${props => props.border};
  border-radius: ${props => props.borderRadius};

  cursor: ${props => props.hasPointer ? "pointer" : null};
`

export {Badge}