import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { client } from "utils/api-client";

const errors = [401, 403, 404, 500];

const useGetDeliveryReports = (filters = {}) => {
  const { association, pageNumber } = filters;
  const { data, isLoading, isError, isSuccess, isPreviousData } = useQuery(
    {
      queryKey: ["delivery-reports", filters],
      queryFn: () =>
        client(`admin/reports/production-delivery/?page=${pageNumber}`, {
          params: {
            association,
          },
        }).then((data) => data),
      keepPreviousData: true,
      useErrorBoundary: (error) =>
        errors.includes(parseInt(error.response?.status)),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
    {
      onError: (err) => {
        throw new Error(err);
      },
    }
  );
  return {
    data: data ?? [],
    isLoading,
    isError,
    isSuccess,
  };
};

const useAddNewDeliveryReport = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) =>
      client("admin/reports/production-delivery/", {
        method: "POST",
        data,
      }),
    {
      onSuccess: (data) => queryClient.refetchQueries(["delivery-reports"]),
      onError: (err) => {
        throw new Error(err);
      },
    }
  );
};

export { useGetDeliveryReports, useAddNewDeliveryReport };
