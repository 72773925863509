import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { Button, Error, Input, Modal } from "UI/atoms";
import checkIcon from "shared/images/check2.svg";
import { validatorMeasureTypes } from "../../utils/validatorSchama";
import Close from "shared/images/xcircle.svg";
import { useAddItem } from "pages/Settings/queries/settings";
import { useEffect } from "react";

const MeasureTypes = ({
  isOpen,
  toggleModal,
  closeModal,
  title,
  addItem,
  onSuccesToast,
  itemToEdit,
  editItem,
  setSelectItemId,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      name: "",
      abbreviation: "",
    },
    resolver: yupResolver(validatorMeasureTypes),
  });

  const messageErrors = {
    name: "El nombre ingresado ya existe",
    abbreviation: "La abreviación ingresada ya existe",
  };

  const addOrEditItem = (e, action) => {
    e.preventDefault();

    switch (action) {
      case "add":
        handleSubmit((data) => {
          addItem(data, {
            onError: (err) => {
              const errorsInputs = JSON.parse(err.request.response);

              const errorsFormat = Object.keys(errorsInputs).map((key) => {
                return {
                  type: key,
                  value: errorsInputs[key],
                  message: messageErrors[key],
                };
              });

              errorsFormat?.map((err) =>
                setError(`${err.type}`, { message: `${err.message}` })
              );
            },
            onSuccess: () => {
              onSuccesToast("success", "Medida agregada con éxito");
              setSelectItemId("");
              reset();
            },
          });
        })();
        break;
      case "edit":
        handleSubmit((data) => {
          editItem(data, {
            onError: (err) => {
              const errorsInputs = JSON.parse(err.request.response);
              const errorsFormat = Object.keys(errorsInputs).map((key) => {
                return {
                  type: key,
                  value: errorsInputs[key],
                  message: messageErrors[key],
                };
              });
              errorsFormat?.map((err) =>
                setError(`${err.type}`, { message: `${err.message}` })
              );
            },
            onSuccess: () => {
              onSuccesToast("success", "Medida editada con éxito");
              setSelectItemId("");
              reset();
            },
          });
        })();
        break;
      default:
        console.log("Default");
    }
  };

  useEffect(() => {
    if (itemToEdit) {
      setValue("name", itemToEdit?.name);
      setValue("abbreviation", itemToEdit?.abbreviation);
    }
    if (!isOpen) {
      clearErrors();
    }
  }, [itemToEdit, setValue, isOpen, clearErrors]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggleModal={toggleModal}
        width="600px"
        padding="34px 38px"
        className="modal-ajustes"
      >
        <form
          className="ajustes-fields"
          onSubmit={(e) =>
            addOrEditItem(e, itemToEdit.length === 0 ? "add" : "edit")
          }
        >
          <div className="modal-head">
            <h1>{title}</h1>
            <Button width="40px" height="40px">
              <img src={Close} onClick={closeModal} alt="Cerrar modal"/>
            </Button>
          </div>
          <div className="row">
            <div className="field">
              <label htmlFor="name">Nombre</label>
              <Input
                placeholder="Nombre"
                id="name"
                type="text"
                border="1px solid #908892"
                {...register("name")}
                name="name"
              />
            </div>
            <Error>{errors?.name?.message}</Error>
          </div>
          <div className="row">
            <div className="field">
              <label htmlFor="abbreviation">Abreviatura</label>
              <Input
                placeholder="Abreviatura"
                type="text"
                id="abbreviation"
                name="email"
                {...register("abbreviation")}
                border="1px solid #908892"
              />
            </div>
            <Error>{errors?.abbreviation?.message}</Error>
          </div>

          <div className="actions">
            <Button
              background="#413A44"
              color="white"
              width="140px"
              height="44px"
              fontSize="1.4em"
              className="action-btn"
              fontWeight="400"
              type="submit"
            >
              <img src={checkIcon} width="18" alt="Guardar cambios" />
              Confirmar
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default MeasureTypes;
