import { useState } from "react";

import Box from "./Box";
import { ModalLocation } from "./Forms/Associations";
import { ModalCommunities } from "./Forms/Communities";
import { ModalAssociation } from "./Forms/Location";
import { useNotifierFactory } from "hooks/useNotifierFactory";
import {
  useAddItem,
  useDeleteItem,
  useEditItem,
  useGetItemById,
  useGetSettings,
} from "../queries/settings";
import { ModalCustomers } from "./Forms/Customers";
import ModalDelete from "../ModalDelete";

const Organization = () => {
  const { toastType } = useNotifierFactory();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [openModalToDelete, setOpenModalToDelete] = useState(false);

  const [endpoint, setEndpoint] = useState("");
  const [title, setTitle] = useState("");
  const [selectItemId, setSelectItemId] = useState("");
  const [idToEditOrDelete, setIdToEditOrDelete] = useState("");
  const [nameItemToDelete, setNameItemToDelete] = useState("");

  const { data: locations, isLoading: loadingLocation } =
    useGetSettings("locations");
  const { data: communities, isLoading: loadingCommunities } =
    useGetSettings("communities");
  const { data: associations, isLoading: loadingAssociation } =
    useGetSettings("associations");
  const { data: customers, isLoading: loadingCustomers } =
    useGetSettings("customers");

  const { mutate: addItem } = useAddItem(endpoint);
  const { data: itemToEdit } = useGetItemById(endpoint, idToEditOrDelete);
  const { mutate: editItem } = useEditItem(endpoint, idToEditOrDelete);

  const openModal = (title, endpoint, action) => {
    setIsOpenModal(true);
    setTitle(title);
    setEndpoint(endpoint);

    switch (action) {
      case "add":
        setIdToEditOrDelete("");
        break;
      case "edit":
        setIdToEditOrDelete(selectItemId);
        break;
      default:
        console.log("Default");
    }
  };

  const onSuccesToast = (type, message) => {
    toastType(type, message, 4000);
    setIsOpenModal(false);
  };

  const onErrorToast = (type, message) => {
    toastType(type, message, 4000);
    setIsOpenModal(false);
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };

  const selectItem = (id) => {
    setSelectItemId(id);
  };

  const organizations = [
    {
      elements: locations,
      title: "Ubicación",
      query: "locations",
      loading: loadingLocation,
    },
    {
      elements: communities,
      title: "Zona",
      query: "communities",
      loading: loadingCommunities,
    },
    {
      elements: associations,
      title: "Asociación",
      query: "associations",
      loading: loadingAssociation,
    },
    {
      elements: customers,
      title: "Clientes",
      query: "customers",
      loading: loadingCustomers,
    },
  ];

  const deleteItem = (endpoint, item) => {
    setOpenModalToDelete(true);
    setEndpoint(endpoint);
    setNameItemToDelete(item?.name);
    setIdToEditOrDelete(item?.id);
  };

  return (
    <>
      <div className="ajustes">
        <div className="cols">
          {organizations?.map((obj) => (
            <Box
              key={obj.title}
              elements={obj.elements}
              title={obj.title}
              endpoint={obj.query}
              openModal={openModal}
              selectItemId={selectItemId}
              selectItem={selectItem}
              deleteItem={deleteItem}
              loading={obj.loading}
            />
          ))}
        </div>
      </div>

      <ModalDelete
        isOpen={openModalToDelete}
        toggleModal={setOpenModalToDelete}
        nameItemToDelete={nameItemToDelete}
        setOpenModalToDelete={setOpenModalToDelete}
        endpoint={endpoint}
        idToEditOrDelete={idToEditOrDelete}
        onSuccesToast={onSuccesToast}
        setIdToEditOrDelete={setIdToEditOrDelete}
        onErrorToast={onErrorToast}
      />

      {endpoint === "locations" && (
        <ModalLocation
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          title={title}
          addItem={addItem}
          onSuccesToast={onSuccesToast}
          closeModal={closeModal}
          itemToEdit={itemToEdit}
          editItem={editItem}
          setSelectItemId={setSelectItemId}
        />
      )}

      {endpoint === "communities" && (
        <ModalCommunities
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          title={title}
          addItem={addItem}
          onSuccesToast={onSuccesToast}
          closeModal={closeModal}
          itemToEdit={itemToEdit}
          editItem={editItem}
          setSelectItemId={setSelectItemId}
        />
      )}

      {endpoint === "associations" && (
        <ModalAssociation
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          title={title}
          addItem={addItem}
          onSuccesToast={onSuccesToast}
          closeModal={closeModal}
          itemToEdit={itemToEdit}
          editItem={editItem}
          setSelectItemId={setSelectItemId}
        />
      )}
      {endpoint === "customers" && (
        <ModalCustomers
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          title={title}
          closeModal={closeModal}
          itemToEdit={itemToEdit}
          addItem={addItem}
          setSelectItemId={setSelectItemId}
          onSuccesToast={onSuccesToast}
          editItem={editItem}
        />
      )}
    </>
  );
};

export default Organization;
