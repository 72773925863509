import React from "react";
import styled from "styled-components";

const Label = ({ children, fontSize, color, className, style, htmlFor }) => {
  return (
    <StyledLabel
      fontSize={fontSize}
      color={color}
      className={className}
      style={style}
      htmlFor={htmlFor}
    >
      {children}
    </StyledLabel>
  );
};

const StyledLabel = styled.label`
  font-size: ${(props) => props.fontSize};
  color: ${(props) => props.color};
`;

export { Label };
