import axios from "axios";

const localStorageKey = "__auth_provider_token__";
const localStorageRefreshKey = "__auth_provider_refresh__";

const handleUserResponse = (data) => {
  window.localStorage.setItem(localStorageKey, data.access);
  window.localStorage.setItem(localStorageRefreshKey, data.refresh);

  return data;
};

export const getToken = () => {
  return window.localStorage.getItem(localStorageKey);
};

export const getRefreshToken = () => {
  return window.localStorage.getItem(localStorageRefreshKey);
};

export const login = async (data) => {
  return client("token/", data).then(handleUserResponse);
};

export const logout = async () => {
  window.localStorage.removeItem(localStorageKey);
};

const authURL = process.env.REACT_APP_API_URL;
const client = async (endpoint, data, token) => {
  const config = {
    method: "POST",
    data: data ? data : undefined,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : undefined,
    },
    auth: {
      userName: data?.userName,
      password: data?.password,
    },
  };

  const req = axios(`${authURL}/${endpoint}`, config).then(async (response) => {
    const data = response.data;
    if (response.status === 200) {
      return data;
    } else {
      return Promise.reject(data);
    }
  });

  return req;
};
