import { Select } from "UI/atoms";
import { CaretDown, Spinner } from "shared/assets/svgs";

const SelectWithlabel = ({
  label,
  isLoading,
  name,
  onSetterFn,
  items,
  defaultOption = "Todas",
}) => {
  return (
    <li style={{ marginRight: "20px" }}>
      <label>{label}:</label>
      <div className="container-select">
        {isLoading ? (
          <Spinner />
        ) : (
          <Select
            name={name}
            defaultValue=""
            onChange={(e) => onSetterFn(e.target.value)}
            outline={"none"}
          >
            <option value="">{defaultOption}</option>
            {items.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </Select>
        )}
      </div>
    </li>
  );
};

export { SelectWithlabel };
