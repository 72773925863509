import React, { forwardRef } from "react";
import styled from "styled-components";

const Input = forwardRef(
  (
    {
      name,
      id,
      value,
      defaultChecked,
      onChange,
      defaultValue,
      type,
      placeholder,
      bg,
      hasBorder = false,
      padding,
      width,
      className,
      height = "46px",
      fontSize,
      fontWeight = "normal",
      min,
      max,
      border,
      autoFocus,
      style,
      pattern,
      accept,
      maxLength,
      checked,
      key,
      step,
      readOnly,
      disabled,
    },
    ref
  ) => {
    return (
      <>
        <StyledInput
          onChange={onChange}
          defaultValue={defaultValue}
          name={name}
          value={value}
          type={type}
          bg={bg}
          hasBorder={hasBorder}
          placeholder={placeholder}
          padding={padding}
          width={width}
          height={height}
          className={className}
          border={border}
          fontSize={fontSize}
          fontWeight={fontWeight}
          autoFocus={autoFocus}
          style={style}
          ref={ref}
          readOnly={readOnly}
          defaultChecked={defaultChecked}
          min={min}
          max={max}
          pattern={pattern}
          accept={accept}
          maxLength={maxLength}
          checked={checked}
          id={id}
          key={key}
          step={step}
          disabled={disabled}
        />
      </>
    );
  }
);
// Yo extendería el objeto de style también, cosa de que tengas más inversión de control en temas de diseño de UI. Sinó cuando tengas edge cases (como en mi caso el font weight) el dev está obligado a seguir extendiendo cada estilo a mano.
const StyledInput = styled.input`
  display: block;

  width: 100%;
  box-sizing: border-box;

  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border: ${(props) => props.border};
  border-radius: 10px;
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  padding: ${(props) => props.padding};
  background: ${(props) => props.bg};

  ::placeholder {
    font-style: italic;
    opacity: 0.5;
    color: #413a44;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    background: #dcd7d7;
  }
`;
export { Input };
