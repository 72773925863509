import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { Button, Error, Input, Modal } from "UI/atoms";
import checkIcon from "shared/images/check2.svg";
import Close from "shared/images/xcircle.svg";
import { validatorGroupModal } from "../../utils/validatorSchama";
import { useEffect } from "react";

const ModalBox = ({
  isOpen,
  toggleModal,
  onSuccesToast,
  closeModal,
  title,
  addItem,
  itemToEdit,
  editItem,
  setSelectItemId,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      name: "",
    },
    resolver: yupResolver(validatorGroupModal),
  });

  const addOrEditItem = (e, action) => {
    e.preventDefault();

    switch (action) {
      case "add":
        handleSubmit((data) => {
          addItem(data, {
            onError: (err) => {
              const nameAlreadyexist = JSON.parse(err.request.responseText)
                .name[0];
              if (nameAlreadyexist) {
                setError("name", {
                  type: "model",
                  message: "El nombre ingresado ya existe",
                });
              }
            },
            onSuccess: () => {
              onSuccesToast("success", "Item agregado con exito");
              setSelectItemId("");
              reset();
            },
          });
        })();

        break;

      case "edit":
        handleSubmit((data) => {
          editItem(data, {
            onError: (err) => {
              const nameAlreadyexist = JSON.parse(err.request.responseText)
                .name[0];
              if (nameAlreadyexist) {
                setError("name", {
                  type: "model",
                  message: "El nombre ingresado ya existe",
                });
              }
            },
            onSuccess: () => {
              onSuccesToast("success", "Item editado con exito");
              setSelectItemId("");
              reset();
            },
          });
        })();
        break;

      default:
    }
  };

  useEffect(() => {
    if (itemToEdit) {
      setValue("name", itemToEdit?.name);
    }
    if (!isOpen) {
      clearErrors();
    }
  }, [itemToEdit, setValue, isOpen, clearErrors]);

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={toggleModal}
      width="600px"
      padding="34px 38px"
      className="modal-ajustes"
    >
      <div className="modal-head">
        <h1>{title}</h1>
        <Button width="40px" height="40px">
          <img src={Close} onClick={closeModal} alt="Cerrar modal"/>
        </Button>
      </div>

      <form
        className="ajustes-fields"
        onSubmit={(e) =>
          addOrEditItem(e, itemToEdit.length === 0 ? "add" : "edit")
        }
      >
        <div className="row">
          <div className="field">
            <label htmlFor="name">Nombre</label>
            <Input
              placeholder="Nombre"
              id="name"
              type="text"
              border="1px solid #908892"
              {...register("name")}
              name="name"
            />
          </div>
          <Error>{errors?.name?.message}</Error>
        </div>

        <div className="actions">
          <Button
            background="#413A44"
            color="white"
            width="140px"
            height="44px"
            fontSize="1.4em"
            className="action-btn"
            fontWeight="400"
            type="submit"
          >
            <img src={checkIcon} width="18" alt="Guardar cambios" />
            Confirmar
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ModalBox;
