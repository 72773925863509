import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { client } from "utils/api-client";

const errors = [401, 403, 404, 500];

const useGetWarehouseCharacteristic = (ep) => {
  const { data, isLoading, isError, isSuccess } = useQuery(
    {
      queryKey: [ep],
      queryFn: () => client(`admin/${ep}`).then((data) => data),

      useErrorBoundary: (error) =>
        errors.includes(parseInt(error.response?.status)),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
    {
      onError: (err) => {
        throw new Error(err);
      },
    }
  );
  return {
    data: data ?? [],
    isLoading,
    isError,
    isSuccess,
  };
};

const useGetProductsByWarehouseId = (id) => {
  const { data, isLoading, isError, isSuccess } = useQuery(
    {
      queryKey: ["stock", id],
      queryFn: () =>
        client(`admin/warehouses/${id}/stock/`).then((data) => data),

      useErrorBoundary: (error) =>
        errors.includes(parseInt(error.response?.status)),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!id,
    },
    {
      onError: (err) => {
        throw new Error(err);
      },
    }
  );
  return {
    data: data ?? [],
    isLoading,
    isError,
    isSuccess,
  };
};

const useGetSellOrdersByWarehouseId = (id) => {
  const { data, isLoading, isError, isSuccess } = useQuery(
    {
      queryKey: ["warehouse-sell-orders", id],
      queryFn: () =>
        client(`admin/warehouses/${id}/sell_orders/`).then((data) => data),

      useErrorBoundary: (error) =>
        errors.includes(parseInt(error.response?.status)),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!id,
    },
    {
      onError: (err) => {
        throw new Error(err);
      },
    }
  );
  return {
    data: data ?? [],
    isLoading,
    isError,
    isSuccess,
  };
};

const useGetStockMovementsByWarehouseId = (id) => {
  const { data, isLoading, isError, isSuccess } = useQuery(
    {
      queryKey: ["stock-movement", id],
      queryFn: () =>
        client(`admin/warehouses/${id}/stock-movements/`).then((data) => data),

      useErrorBoundary: (error) =>
        errors.includes(parseInt(error.response?.status)),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!id,
    },
    {
      onError: (err) => {
        throw new Error(err);
      },
    }
  );
  return {
    data: data ?? [],
    isLoading,
    isError,
    isSuccess,
  };
};

const useAddNewWarehouse = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) =>
      client("admin/warehouses/", {
        method: "POST",
        data,
      }),
    {
      onSuccess: (data) => queryClient.refetchQueries(["warehouses"]),
      onError: (err) => {
        throw new Error(err);
      },
    }
  );
};

const useTransferStockBetweenWarehouses = (warehouseId) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) =>
      client("admin/warehouses-stock-transfer/", {
        method: "POST",
        data,
      }),
    {
      onSuccess: (data) => {
        Promise.all(
          queryClient.refetchQueries(["stock-movement", warehouseId]),
          queryClient.refetchQueries(["stock", warehouseId]),
          queryClient.refetchQueries(["warehouse-available-items", warehouseId])
        );
      },
      onError: (err) => {
        throw new Error(err);
      },
    }
  );
};

export {
  useGetWarehouseCharacteristic,
  useGetStockMovementsByWarehouseId,
  useAddNewWarehouse,
  useTransferStockBetweenWarehouses,
  useGetProductsByWarehouseId,
  useGetSellOrdersByWarehouseId,
};
