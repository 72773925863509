import React from "react";
import { Button, Input, Modal } from "UI/atoms";
import checkIcon from "shared/images/check2.svg";
import { DownloadFileRow } from "../../molecules/DownloadFileRow";
import { useParams } from "react-router-dom";
import { client } from "utils/api-client";

const DownloadFilesModal = ({ isOpen, toggleModal, files }) => {
  const { id } = useParams();

  const handleDownload = () => {
    const url = `admin/reports/production-delivery/${id}/download_zip/`;
    const link = document.createElement("a");
    link.target = "_blank";
    client(url, {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/force-download",
        "Content-Disposition": "attachment; filename=url",
      },
    })
      .then((res) => {
        const blob = new Blob([res], { type: "application/zip" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "archivo.zip");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={toggleModal}
      width="600px"
      padding="34px 38px"
      borderRadius="20px"
      className="modal-ajustes"
    >
      <div>
        <h1
          style={{
            fontWeight: 700,
            fontSize: "20px",
            lineHeight: "24px",
            marginBottom: "20px",
          }}
        >
          Descargar archivos
        </h1>
      </div>
      <div className="entregas-table-container">
        <table className="entregas-table">
          <tbody className="entregas-table-headers">
          </tbody>

          {files.map((file) => (
            <DownloadFileRow key={file.id} file={file} />
          ))}
        </table>
      </div>
      <ul style={{ display: "flex", justifyContent: "flex-end" }}>
        <li>
          <Button
            id="download"
            background="#339589"
            color="white"
            width="140px"
            height="44px"
            fontSize="1.4em"
            className="action-btn"
            fontWeight="400"
            type="button"
            onClick={() => handleDownload()}
          >
            <img
              src={checkIcon}
              width="18"
              alt="Descargar"
              style={{ marginRight: "10px" }}
            />
            Todo (ZIP)
          </Button>
          ;
        </li>
      </ul>
    </Modal>
  );
};

export { DownloadFilesModal };
