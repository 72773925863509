import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { Button, Error, Input, Modal } from "UI/atoms";
import checkIcon from "shared/images/check2.svg";
import Close from "shared/images/xcircle.svg";
import { useAddNewWarehouse } from "pages/Stock/queries/warehouses";
import * as yup from "yup";

const validateSchema = yup.object().shape({
  name: yup.string().required("El nombre es requerido"),
});

const ModalBox = ({ isOpen, toggleModal, onSuccesToast }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validateSchema),
  });

  const { mutate: addWarehouse } = useAddNewWarehouse();

  const onSubmit = (data) => {
    addWarehouse(data, {
      onError: (err) => {
        console.log(err);
      },
      onSuccess: () => {
        onSuccesToast();
        reset();
        toggleModal(false);
      },
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={toggleModal}
      width="600px"
      padding="34px 38px"
      className="modal-ajustes"
    >
      <div className="modal-head">
        <h1>Agregar deposito</h1>
        <Button width="40px" height="40px">
          <img
            src={Close}
            onClick={() => toggleModal(false)}
            alt="Cerrar modal"
          />
        </Button>
      </div>
      <form className="ajustes-fields" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="field">
            <label htmlFor="name">Nombre</label>
            <Input
              placeholder="Nombre"
              id="name"
              type="text"
              border="1px solid #908892"
              defaultValue=""
              {...register("name")}
              name="name"
            />
          </div>
          <Error>{errors?.name?.message}</Error>
        </div>

        <div className="actions">
          <Button
            background="#413A44"
            color="white"
            width="140px"
            height="44px"
            fontSize="1.4em"
            className="action-btn"
            fontWeight="400"
            type="submit"
          >
            <img src={checkIcon} width="18" alt="Guardar cambios" />
            Confirmar
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export { ModalBox };
