import { CaretDown } from "shared/assets/svgs";
import OrderBy from "shared/assets/svgs/OrderBy";

const TableHeaders = ({onHandleSortBy}) => {
  return (
    <thead>
      <tr>
      <th>
          <div style={{display: 'flex', justifyContent: 'flex-start'}}>
              Coordinadora
              <span onClick={() => {
                onHandleSortBy("user")}}>
                  <OrderBy />
              </span> 
            </div>
          </th>
        <th>
          <div style={{display: 'flex', justifyContent: 'flex-start'}}>
              Artesana
              <span onClick={() => {
                onHandleSortBy("artisan")}}>
                  <OrderBy />
              </span> 
            </div>
          </th>
        <th>
          <div>Código producto</div>
        </th>
        <th>Descripción producto</th>
        <th>Cantidad</th>
        <th>Calidad</th>
        <th>$ Total</th>
        <th>Detalles</th>
      </tr>
    </thead>
  );
};

export { TableHeaders };
