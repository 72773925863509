import React from "react";

const PencilEditCircle = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="15" fill="#339589" />
      <path
        d="M13 20.4999H10C9.86739 20.4999 9.74021 20.4472 9.64645 20.3535C9.55268 20.2597 9.5 20.1325 9.5 19.9999V17.207C9.5 17.1414 9.51293 17.0764 9.53806 17.0157C9.56319 16.955 9.60002 16.8999 9.64645 16.8535L17.1464 9.35348C17.2402 9.25971 17.3674 9.20703 17.5 9.20703C17.6326 9.20703 17.7598 9.25971 17.8536 9.35348L20.6464 12.1464C20.7402 12.2401 20.7929 12.3673 20.7929 12.4999C20.7929 12.6325 20.7402 12.7597 20.6464 12.8535L13 20.4999Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 11L19 14.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { PencilEditCircle };
