import React from "react";
import PlusIcon from "shared/images/plus.svg";
import BellIcon from "shared/images/bell.svg";
import Avatar from "shared/images/administrator.jpg";

import { Button, Search } from "UI/atoms/index";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  return (
    <header className="dashhead">
      <h3>
        COMAR
        <br />
        <span>Administrador</span>
      </h3>
      <ul className="nav">
        {/*
        <li>
          <div className="alert">
            <img src={BellIcon} width="28" />
            <span className="on" />
          </div>
        </li>
        <li>
          <figure>
            <img src={Avatar} width="50" />
          </figure>
        </li>
        */}
        {/* <li>
          <div className="alert">
            <img src={BellIcon} width="28" />
            <span className="on" />
          </div>
        </li>
        <li>
          <figure>
            <img src={Avatar} width="50" />
          </figure>
        </li> */}
      </ul>
    </header>
  );
};

export { Header };
