import { useEffect, useState } from "react";
import winguLogo from "shared/images/wingu.svg";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { EyeSlash as EyeIcon } from "shared/assets/svgs/EyeSlash";
import { Button, Input, Label, Error } from "UI/atoms/index";
import { useAsync } from "hooks/useAsync";
import { useAuth } from "auth/context";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const schema = yup
  .object({
    username: yup.string().required("Por favor escriba su usuario"),
    password: yup.string().required("Por favor escriba su contraseña"),
  })
  .required();

const Login = () => {
  const navigate = useNavigate();
  const { run, isSuccess, isError, setError } = useAsync();
  const { login, user } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const handleSignIn = (data) => {
    run(login(data));
  };

  if (isSuccess && user.roles?.includes("admin")) {
    navigate("/orders");
  }
  return (
    <div className="start">
      <div className="cols">
        <div className="chaco">
          <h1>Artesanías</h1>
          <h2>Chaco Innova</h2>
          <h3>
            Organizaciones de la
            <br /> cooperativa de mujeres
            <br /> artesanas del gran Chaco.
          </h3>
          <h4>
            Comunidades Wichí,
            <br /> Qomle’ec y Pilagá.
          </h4>
          <h5>
            Desarrollado por{" "}
            <figure>
              <img src={winguLogo} width="95" alt="Logo de wingu" />
            </figure>
          </h5>
        </div>
        <form onSubmit={handleSubmit(handleSignIn)} className="login">
          <h3>Iniciar sesión</h3>
          <div className="field">
            <Label htmlFor="username">Usuario</Label>
            <Input
              {...register("username")}
              placeholder="Nombre de usuario"
              type="text"
              name="username"
              className="username"
            />
          </div>
          <Error color="white">{errors.username?.message}</Error>
          <div className="field">
            <Label htmlFor="password">Contraseña</Label>
            <Input
              {...register("password")}
              type="password"
              name="password"
              className="password"
              placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
            />
            {/* <span className="lock">
              <EyeIcon />
            </span> */}
          </div>
          <Error color="white">{errors.password?.message}</Error>
          {/* <div className="forget">
            <span>¿Olvidaste la contraseña?</span>
          </div> */}
          <Button
            background="#339589"
            color="#fff"
            fontSize="1.6em"
            fontWeight="600"
            height="54px"
            type="submit"
          >
            Ingresar
          </Button>
          <p>
            {isError || (isSuccess && !user.roles?.includes("admin"))
              ? "Credenciales incorrectas"
              : null}
          </p>
        </form>
      </div>
    </div>
  );
};

export { Login };
