import React from "react";
import { Button } from "UI/atoms";
import deleteIcon from "shared/images/card-delete.svg";
import editIcon from "shared/images/card-edit.svg";
import addIcon from "shared/images/card-add.svg";
import { Spinner } from "shared/assets/svgs";

const Box = ({
  openModal,
  title,
  elements,
  query,
  selectItem,
  selectItemId,
  deleteItem,
  endpoint,
  loading,
}) => {
  const findItemSelected = elements.find((obj) => obj.id === selectItemId);

  return (
    <div className="col">
      <h4>{title}</h4>
      <div className="card">
        <ul>
          {loading && (
            <div className="spinner-items-ajustes">
              <Spinner />
            </div>
          )}
          {elements.map((e) => (
            <li
              key={e.id}
              onClick={() => selectItem(e.id)}
              className={selectItemId === e.id ? "selected" : null}
            >
              {e.name}
            </li>
          ))}
          {elements.length === 0 && <span className="no_items_ajustes">No hay items</span>}
        </ul>
      </div>
      <ul className="card-foot">
        <li>
          <Button
            width="auto"
            height="auto"
            borderRadius="0"
            background="transparent"
            fontSize="1.4em"
            onClick={() => deleteItem(endpoint, findItemSelected)}
            className={findItemSelected ? null : "btn-disabled"}
          >
            <img src={deleteIcon} width="22" alt="Eliminar" />
            Eliminar
          </Button>
        </li>
        <li>
          <Button
            width="auto"
            height="auto"
            borderRadius="0"
            background="transparent"
            fontSize="1.4em"
            className={!findItemSelected ? "btn-disabled" : ""}
            onClick={() => openModal(title, endpoint, "edit")}
            disabled={!findItemSelected}
          >
            <img src={editIcon} width="22" alt="Editar" />
            Editar
          </Button>
        </li>
        <li>
          <Button
            width="auto"
            height="auto"
            borderRadius="0"
            background="transparent"
            fontSize="1.4em"
            onClick={() => openModal(title, endpoint, "add")}
          >
            <img src={addIcon} width="22" alt="Agregar" />
            Agregar
          </Button>
        </li>
      </ul>
    </div>
  );
};

export default Box;
