import { Button } from "UI/atoms";
import Pencil from "shared/images/PencilLineGreen.svg";
import { isValidObject } from "utils/isValidObject";

const ReportRow = ({ report, onSetReport, onSetIsOpenReportModal }) => {
  const { coordinator, artisan } = isValidObject(
    report.from_artisan_production_order_item
  )
    ? report.from_artisan_production_order_item
    : "-";
  const { code, short_description } = isValidObject(
    report.from_artisan_production_order_item.product
  )
    ? report.from_artisan_production_order_item.product
    : "-";
  const { quantity } = report;
  const { total } = isValidObject(report.amounts) ? report.amounts : "-";

  const handleOnDetailClick = (report) => {
    onSetReport(report);
    onSetIsOpenReportModal(true);
  };
  return (
    <tr
      style={{
        backgroundColor: "white",
      }}
    >
      <td>{coordinator}</td>
      <td>{artisan}</td>
      <td>{code}</td>
      <td>{short_description}</td>
      <td className="center">{quantity}</td>
      <td className="center">{report?.price_list_batch?.batch?.name}</td>
      <td>{total}</td>
      <td>
        <Button
          width="auto"
          height="auto"
          borderRadius="0"
          background="transparent"
          className="edit"
          onClick={() => handleOnDetailClick(report)}
        >
          <img src={Pencil} width="26" alt="Editar" />
        </Button>
      </td>
    </tr>
  );
};

export { ReportRow };
