import React, { useState, useEffect } from 'react';
import { CaretDown, Spinner } from "shared/assets/svgs";
import MagnifityGlass from 'shared/assets/svgs/MagnifityGlass';

const InputWithDropdown = ({ 
  label, 
  isLoading, 
  items, 
  onSetOptionSelected,
  width,
  alignItems,
  flexDirection,
  value,
  placeholder,
  inputValue,
  onSetInputValue,
}) => {
  const [filteredItems, setFilteredItems] = useState(items);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  useEffect(() => {
    setFilteredItems(items);
  }, [items]);

  const handleChange = (e) => {
    const value = e.target.value;
    onSetInputValue(value);
    const filtered = value ? items.filter(item => item.name.toLowerCase().includes(value.toLowerCase())) : items;
    setFilteredItems(filtered);
    setIsDropdownVisible(value !== '' && filtered.length > 0);
    if (!value) onSetOptionSelected(null);
  };

  const handleSelect = (id) => {
    const selectedItem = items.find(item => item.id === id);
    onSetInputValue(selectedItem?.name || '');
    setFilteredItems(items);
    setIsDropdownVisible(false);
    onSetOptionSelected(id);
  };

  return (
    <div style={{ marginRight: "20px", display: 'flex', alignItems: 'center' }}>
      <label>{label}:</label>
      <div className="input-container">
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <input
              type="text"
              value={inputValue}
              onChange={handleChange}
              placeholder={'Busca por nombre o apellido'}
            />
              <MagnifityGlass />
              {isDropdownVisible && (
              <ul>
                {filteredItems.map(item => (
                  <li key={item.id} onClick={() => handleSelect(item.id)}>
                    {item.name}
                  </li>
                ))}
              </ul>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export { InputWithDropdown };