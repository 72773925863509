import React from 'react'
import styled from 'styled-components'

const Search = ({ 
  name,
  value,
  defaultValue,
  type,
  hasBorder = false,
  padding,
  width = '100%',
  height = '44px',
  onChange,
  placeholder, 
  bg = '#fff',
  className,
  border = 'none',
  borderRadius = '10px',
  fontWeight = '500',
  fontSize = '1.4em',
  color = '#908892'
}) => {
  return (
    <>
      <StyledInput 
        name={name}
        defaultValue={defaultValue}
        value={value}
        type={type}
        width={width}
        height={height}
        onChange={onChange}
        bg={bg}
        hasBorder={hasBorder}
        placeholder={placeholder}
        padding={padding}
        className={className}
        border={border}
        borderRadius={borderRadius}
        fontWeight={fontWeight}
        fontSize={fontSize}
        color={color}
      />
    </>
  )
}

const StyledInput = styled.input`
  display: block;
  
  width: ${props => props.width};
  height: ${props => props.height};
  border: ${props => props.border};
  border-radius: ${props => props.borderRadius};
  padding: ${props => props.padding};

  background-color: ${props => props.bg};

  color: ${props => props.color};
  font-weight: ${props => props.fontWeight};
  font-size: ${props => props.fontSize};

  &:focus-visible {
    outline: none;
  }
`

export {Search}

