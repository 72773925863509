import React from "react";

const DoubleCaretRight = () => {
  return (
    <svg
      enableBackground="new 0 0 26 26"
      width="14"
      id="Layer_1"
      version="1.1"
      viewBox="0 0 26 26"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{ transform: "rotate(180deg)" }}
    >
      <g>
        <polygon
          fill="#231F20"
          points="23.885,0.58 25.969,2.664 15.133,13.5 25.969,24.336 23.885,26.42 10.965,13.5  "
        />
        <polygon
          fill="#231F20"
          points="12.885,0.58 14.969,2.664 4.133,13.5 14.969,24.336 12.885,26.42 -0.035,13.5  "
        />
      </g>
    </svg>
  );
};

export { DoubleCaretRight };
