const DownloadCloud = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11" cy="11" r="11" fill="#339589" />
      <path
        d="M10.687 13.5186L12.6095 15.3747L14.5319 13.5186"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6094 11V15.375"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.43734 15.375H8.07797C7.62815 15.3746 7.18357 15.2818 6.77373 15.1028C6.3639 14.9238 5.99818 14.6627 5.70086 14.3367C5.40354 14.0108 5.18142 13.6276 5.04923 13.2125C4.91704 12.7973 4.87782 12.3598 4.93416 11.9289C4.9905 11.498 5.14111 11.0836 5.37601 10.7133C5.61091 10.3429 5.92471 10.025 6.29659 9.78062C6.66847 9.53628 7.08993 9.37111 7.53298 9.29607C7.97603 9.22102 8.43055 9.23782 8.86636 9.34535"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.53125 10.9996C8.53132 10.3064 8.70199 9.62311 9.0292 9.00609C9.35641 8.38907 9.83079 7.85595 10.4133 7.45066C10.9957 7.04537 11.6696 6.77952 12.3793 6.67501C13.0891 6.5705 13.8144 6.63032 14.4955 6.84954C15.1766 7.06877 15.794 7.44112 16.2969 7.93592C16.7997 8.43072 17.1735 9.0338 17.3876 9.69546C17.6017 10.3571 17.6499 11.0584 17.5282 11.7416C17.4065 12.4248 17.1184 13.0702 16.6876 13.6248"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { DownloadCloud };
