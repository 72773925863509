import { useState } from 'react';

const useSortHandler = (initialData) => {
  const [sortedData, setSortedData] = useState({ key: '', direction: 'asc' });

  const handleSortBy = (key) => {
    setSortedData(prevState => ({
      key,
      direction: prevState.key === key && prevState.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const getSortedData = () => {
    return initialData.sort((a, b) => {
      const direction = sortedData.direction === 'asc' ? 1 : -1;
      const A = a[sortedData.key];
      const B = b[sortedData.key];

      if (typeof A === 'string' && typeof B === 'string') {
        return A.localeCompare(B, undefined, { sensitivity: 'base' }) * direction;
      }
      return A > B ? direction : -direction;
    });
  };

  return {
    handleSortBy,
    sortedData: getSortedData()
  };
};

export default useSortHandler;
