import axios from "axios";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";

const apiURL = process.env.REACT_APP_API_URL;
const localStorageToken = "__auth_provider_token__";
const localStorageRefresh = "__auth_provider_refresh__";

async function client(
  endpoint,
  { data, headers: customHeaders, ...customConfig } = {}
) {
  const token = localStorage.getItem(localStorageToken);
  const refresh = localStorage.getItem(localStorageRefresh);
  const config = {
    method: data ? "POST" : "GET",
    data: data ? data : undefined,
    ...customConfig,
  };

  const axiosInstance = axios.create({
    baseURL: apiURL,
    headers: {
      Authorization: `Bearer ${token}`,
      ...customHeaders,
    },
    ...customConfig,
  });

  axiosInstance.interceptors.request.use(async (req) => {
    const decodedToken = jwt_decode(token);
    const isExpired = dayjs.unix(decodedToken.exp).diff(dayjs()) < 1;

    if (!isExpired) return req;

    const response = await axios.post(`${apiURL}/token/refresh/`, {
      refresh,
    });

    const data = response.data;
    if (response.status === 200) {
      localStorage.setItem(localStorageToken, data.access);
      localStorage.setItem(localStorageRefresh, data.refresh);
    }
    req.headers.Authorization = `Bearer ${data.access}`;
    return req;
  });

  const req = axiosInstance(`${apiURL}/${endpoint}`, config).then(
    async (response) => {
      const data = response.data;

      if (
        response.status === 200 ||
        response.status === 201 ||
        response.status === 204
      ) {
        return data;
      } else {
        return Promise.reject(data);
      }
    }
  );

  return req;
}

export { client };
