import { Topbar } from "./UI/molecules/Topbar";
import { useGetWarehouseCharacteristic } from "pages/Stock/queries/warehouses";
import { SelectLi } from "./UI/molecules/SelectLi";
import { DeliveryRow } from "./UI/molecules/DeliveryRow";
import { TableHeaders } from "./UI/atoms/TableHeaders";
import { advicesBuilder } from "utils/advicesBuilder";
import { NoResultsAdvice } from "UI/atoms/NoResultsAdvice";
import { useState } from "react";
import moment from "moment";
import "moment/locale/es";
import { ModalBox } from "./UI/molecules/ModalBox";
import { useGetData } from "shared/queries/commons";
import { useGetDeliveryReports } from "../queries/deliveries";
import { Paginator } from "UI/organisms/Paginator";

const now = moment().format("L");

const defineFromDatetime = (reports) => {
  const hasReports = reports?.results?.length > 0
  if(hasReports) {
    const from = moment(reports?.results[0]?.to_datetime).format("L");
    return from
  } else {
    return now
  }
}


const DeliveriesList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [openNewReportModal, setOpenNewReportModal] = useState(false);
  const [association, setAssociation] = useState();

  const { data: associations, isLoading: isAssociationsLoading } = useGetData(
    "associations",
    "associations"
  );

  const { data: reports, isLoading: isReportsLoading } = useGetDeliveryReports({
    association: association?.id,
    pageNumber: currentPage,
  });

  const handleAssociationChange = (association) => {
    setAssociation(association);
    setCurrentPage(1);
  };


  // the data is ordered in decrescient order, so the first object in the array will always be
  // the latest one generated.
  const from = defineFromDatetime(reports)
  // const from = moment(reports[0]?.to_datetime).format("L");
  const newReport = {
    from_datetime: from,
    to_datetime: now,
    association: association?.name,
    association_id: association?.id,
  };

  const results = advicesBuilder(reports?.results || []);
  const hasAssociationSelected = Boolean(association);

  return (
    <>
      <Topbar
        onSetOpenReportModal={setOpenNewReportModal}
        hasAssociationSelected={hasAssociationSelected}
        embedModalComp={
          <ModalBox
            isOpen={openNewReportModal}
            toggleModal={setOpenNewReportModal}
            newReport={newReport}
          />
        }
      />
      <div className="stock-page-categorias">
        {!isAssociationsLoading ? (
          <ul>
            <SelectLi
              label="Elegir Asociación"
              isLoading={isAssociationsLoading}
              name="associations"
              onSetterFn={handleAssociationChange}
              items={associations}
            />
          </ul>
        ) : null}
      </div>
      <div className="stock-table-container">
        <table className="stock-table">
          <TableHeaders />
          {isReportsLoading
            ? null
            : reports.results.map((report) => (
                <DeliveryRow key={report.id} delivery={report} />
              ))}
        </table>
        {!results.result && !isReportsLoading ? (
          <NoResultsAdvice message={results.message} />
        ) : null}{" "}
      </div>

      {isReportsLoading ? null : (
        <Paginator
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalItems={reports?.count}
        />
      )}
    </>
  );
};

export default DeliveriesList;
