import React from "react";
import { Button, Error, Input, Modal } from "UI/atoms";
import checkIcon from "shared/images/check2.svg";
import { useAddNewDeliveryReport } from "pages/Deliveries/queries/deliveries";
import { useNotifierFactory } from "hooks/useNotifierFactory";

const generateError = (error) => {
  const noDeliveries =
    error === "There are no deliveries for the given association.";
  if (noDeliveries) return "No hay entregas por parte de esta asociacion.";
  else
    return "Algo salio mal, por favor intenta nuevamente. Si el error persiste, contactate con un administrador.";
};

const ModalBox = ({ isOpen, toggleModal, newReport }) => {
  const { from_datetime, to_datetime, association, association_id } = newReport;
  const { mutate: addNewDeliveryReport } = useAddNewDeliveryReport();

  const { toastType } = useNotifierFactory();

  const onSuccesToast = () => {
    toastType(
      "success",
      "Reporte agregado exitosamente",
      "success-report-id",
      2000
    );
  };

  const onErrorToast = (err) => {
    toastType("error", generateError(err), "error-report-id", 4000);
  };

  const handleAddReport = () => {
    const payload = {
      association: association_id,
      remarks: "",
    };
    addNewDeliveryReport(payload, {
      onError: (err) => {
        console.log(err, "err");
        onErrorToast(err.response.data[0]);
      },
      onSuccess: () => {
        onSuccesToast();
        toggleModal(false);
      },
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={toggleModal}
      width="600px"
      padding="34px 38px"
      borderRadius="20px"
      className="modal-ajustes"
    >
      <div style={{ textAlign: "center" }}>
        <h1
          style={{
            fontWeight: 700,
            fontSize: "20px",
            lineHeight: "24px",
            marginBottom: "20px",
          }}
        >
          Nuevo reporte de entregas
        </h1>
        <p
          style={{
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "19px",
            marginBottom: "20px",
          }}
        >
          Ten en cuenta que si no existen entregas desde la última fecha de entrega, no se generará ningún reporte.
        </p>
        <h1
          style={{
            fontWeight: 700,
            fontSize: "20px",
            lineHeight: "24px",
            marginBottom: "20px",
          }}
        >
          {association}
        </h1>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            margin: "15px 0",
          }}
        >
          <div>
            <label
              style={{ fontWeight: 500, fontSize: "12px", lineHeight: "14px" }}
            >
              Desde
            </label>
            <p
              style={{ fontWeight: 700, fontSize: "15px", lineHeight: "16px" }}
            >
              {from_datetime}
            </p>
          </div>
          <div>
            <label
              style={{ fontWeight: 500, fontSize: "12px", lineHeight: "14px" }}
            >
              Hasta
            </label>
            <p
              style={{ fontWeight: 700, fontSize: "15px", lineHeight: "16px" }}
            >
              {to_datetime}
            </p>
          </div>
        </div>
      </div>
      <ul style={{ display: "flex", justifyContent: "space-evenly" }}>
        <li>
          <Button
            background="#DCD7D7"
            color="#413A44"
            width="140px"
            height="44px"
            fontSize="1.4em"
            className="action-btn"
            fontWeight="400"
            type="button"
            onClick={() => toggleModal(false)}
          >
            Cancelar
          </Button>
        </li>
        <li>
          <Button
            background="#339589"
            color="white"
            width="140px"
            height="44px"
            fontSize="1.4em"
            className="action-btn"
            fontWeight="400"
            type="button"
            onClick={() => handleAddReport()}
          >
            <img src={checkIcon} width="18" alt="Guardar cambios" />
            Confirmar
          </Button>
        </li>
      </ul>
    </Modal>
  );
};

export { ModalBox };
