import { useState, useMemo } from "react";
import { useParams } from "react-router-dom";

const useProductsFilter = (products, productsArtisans) => {
  const [query, setQuery] = useState("");
  const [category, setCategory] = useState("");
  const [material, setMaterial] = useState("");
  const [shape, setShape] = useState("");
  const [technique, setTechnique] = useState("");
  const { name } = useParams();

  const filteredProducts = useMemo(() => {
    if (name === "comar") {
      return products.filter((product) => {
        if (
          query &&
          !product.name.toLowerCase().includes(query.toLowerCase()) &&
          !product.code.toString().toLowerCase().includes(query.toLowerCase())
        ) {
          return false;
        }
        if (category && product.category?.name !== category) {
          return false;
        }
        if (material && product.material?.name !== material) {
          return false;
        }
        if (shape && product.shape?.name !== shape) {
          return false;
        }
        if (technique && product.technique?.name !== technique) {
          return false;
        }
        return true;
      });
    } else {
      return productsArtisans.filter((product) => {
        if (
          query &&
          !product.name.toLowerCase().includes(query.toLowerCase())
        ) {
          return false;
        }

        if (material && product.material?.name !== material) {
          return false;
        }
        if (shape && product.shape?.name !== shape) {
          return false;
        }
        if (technique && product.technique?.name !== technique) {
          return false;
        }
        return true;
      });
    }
  }, [
    query,
    category,
    material,
    shape,
    technique,
    products,
    name,
    productsArtisans,
  ]);

  return {
    query,
    setQuery,
    category,
    setCategory,
    material,
    setMaterial,
    shape,
    setShape,
    technique,
    setTechnique,
    filteredProducts,
  };
};

export { useProductsFilter };
