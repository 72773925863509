import { Select } from "UI/atoms";
import { CaretDown, Spinner } from "shared/assets/svgs";

const SelectLi = ({ label, isLoading, name, onSetterFn, items }) => {
  const handleOnChange = (e) => {
    const id = e.target.value;
    const item = items.find((item) => item.id === id);
    onSetterFn(item);
  };

  return (
    <li>
      <label>{label}:</label>

      <div className="container-select">
        {isLoading ? (
          <Spinner />
        ) : (
          <Select
            name={name}
            defaultValue=""
            onChange={(e) => handleOnChange(e)}
            outline={"none"}
          >
            <option value="" disabled>
              Selecciona una opcion
            </option>
            <option value="">Ver todas las asociaciones</option>
            {items.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </Select>
        )}
      </div>
    </li>
  );
};

export { SelectLi };
