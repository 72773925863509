import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { client } from "utils/api-client";
const errors = [401, 403, 404, 500];

const useGetSettings = (param) => {
  const { data, isLoading } = useQuery(
    {
      queryKey: [param],
      queryFn: () => client(`admin/${param}`).then((data) => data),
      useErrorBoundary: (error) =>
        errors.includes(parseInt(error.response?.status)),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
    {
      onError: (err) => {
        throw new Error(err);
      },
    }
  );
  return {
    data: data ?? [],
    isLoading,
  };
};

const useAddItem = (endpoint) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) =>
      client(`admin/${endpoint}/`, {
        method: "POST",
        data,
      }),
    {
      onSuccess: () => queryClient.invalidateQueries([endpoint]),
      onError: (err) => {
        throw new Error(err);
      },
    }
  );
};

const useGetItemById = (endpoint, id) => {
  const { data } = useQuery(
    {
      queryKey: [endpoint, id],
      queryFn: () => client(`admin/${endpoint}/${id}`).then((data) => data),
      useErrorBoundary: (error) => {
        errors.includes(parseInt(error.response?.status));
      },
      enabled: !!id && !!endpoint,
      refetchOnWindowFocus: false,
    },
    {
      onError: (err) => {
        throw new Error(err);
      },
    }
  );

  return { data: data ?? [] };
};

const useEditItem = (endpoint, id) => {
  const queryClient = useQueryClient();

  return useMutation(
    (data) =>
      client(`admin/${endpoint}/${id}/`, {
        method: "PUT",
        data,
      }),
    {
      onSuccess: () => queryClient.invalidateQueries([endpoint]),
      onError: (err) => {
        throw new Error(err);
      },
    }
  );
};
const useDeleteItem = (endpoint, id) => {
  const queryClient = useQueryClient();

  return useMutation(
    () =>
      client(`admin/${endpoint}/${id}/`, {
        method: "DELETE",
      }),
    {
      onSuccess: () => queryClient.invalidateQueries([endpoint]),
      onError: (err) => {
        throw new Error(err);
      },
    }
  );
};
export {
  useGetSettings,
  useAddItem,
  useGetItemById,
  useEditItem,
  useDeleteItem,
};
