import { useMemo } from "react";

const usePaginator = ({
  totalItems = 0,
  itemsPerPage = 20,
  maxVisiblePages = 3,
  currentPage,
  setCurrentPage,
}) => {
  const hasResults = totalItems > 0;
  const totalPages = useMemo(
    () => Math.ceil(totalItems / itemsPerPage),
    [totalItems, itemsPerPage]
  );
  const visiblePages = useMemo(() => {
    const startPage = Math.max(currentPage - 1, 1);
    const endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);
    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  }, [currentPage, maxVisiblePages, totalPages]);

  const hasPaginatorMoreThanThreePages = totalPages >= maxVisiblePages;
  const pageSelected = currentPage === "last" ? totalPages : currentPage;

  const isCurrentPageTheLastPage =
    visiblePages[visiblePages.length - 1] >= totalPages;

  const hasNextPage = useMemo(
    () => currentPage < totalPages,
    [currentPage, totalPages]
  );

  const hasPreviousPage = useMemo(() => currentPage > 1, [currentPage]);

  const handleNextPage = () => setCurrentPage((prevPage) => prevPage + 1);

  const handlePreviousPage = () =>
    setCurrentPage((prevPage) => {
      const isLastPage = prevPage === "last";
      if (isLastPage) {
        // totalPages means the last page available in the paginator,
        // so we are sure the user is positioned in the last page.
        return totalPages - 1;
      } else {
        return prevPage - 1;
      }
    });

  const handleFirstPage = () => setCurrentPage(1);

  const handleLastPage = (page) =>
    setCurrentPage(page === "last" ? totalPages : page);

  const handlePageClick = (page) => setCurrentPage(page);

  return {
    hasPaginatorMoreThanThreePages,
    pageSelected,
    isCurrentPageTheLastPage,
    currentPage,
    totalPages,
    hasNextPage,
    hasPreviousPage,
    visiblePages,
    hasResults,
    handleNextPage,
    handlePreviousPage,
    handleFirstPage,
    handleLastPage,
    handlePageClick,
  };
};

export { usePaginator };
