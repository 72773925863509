const advicesBuilder = (filteredArray) => {
  if (filteredArray?.length === 0) {
    return {
      result: false,
      message: `No encontramos resultados. Por favor intenta nuevamente cambiando los filtros de busqueda.`,
    };
  }
  return { result: true, message: "Con resultados" };
};

export { advicesBuilder };
