import { Modal } from "UI/atoms";
import Close from "shared/images/xcircle.svg";
import { Button } from "UI/atoms";
import { dateTransformer } from "utils/dateTransformer";

const ReportModal = ({ report, isOpenReportModal, onSetIsOpenReportModal }) => {
  return (
    <div>
      <Modal
        isOpen={isOpenReportModal}
        toggleModal={() => onSetIsOpenReportModal(false)}
        height="420px"
        width="680px"
        borderRadius="20px"
        padding="35px"
      >
        <div className="detalle-reportes-row">
          <div>
            <h3>Coordinadora</h3>
            <h6 className="reporte-nombres">
              {report.from_artisan_production_order_item.coordinator}
            </h6>
          </div>
          <div>
            <h3>Artesana</h3>
            <h6 className="reporte-nombres">
              {report.from_artisan_production_order_item.artisan}
            </h6>
          </div>
          <div>
            <Button
              width="40px"
              height="40px"
              onClick={() => onSetIsOpenReportModal((prevState) => !prevState)}
            >
              <img src={Close} alt="Cerrar" />
            </Button>
          </div>
        </div>
        <div className="divisor"></div>
        <div className="detalle-reportes-row">
          <div>
            <h3>Fecha</h3>
            <h6>{dateTransformer(report.created_at)}</h6>
          </div>
          <div>
            <h3>Deposito</h3>
            <h6>{report.to_warehouse?.name}</h6>
          </div>
          <div>
            <h3>Asociacion</h3>
            <h6>{report?.association?.name || "-"}</h6>
          </div>
          <div>
            <h3>Grupo de trabajo</h3>
            <h6>{report?.warehouse?.name || "-"}</h6>
          </div>
        </div>
        <div className="divisor"></div>

        <div className="detalle-reportes-row">
          <div>
            <h3>Codigo producto</h3>
            <h6>
              {report?.from_artisan_production_order_item?.product?.code || "-"}
            </h6>
          </div>
          <div>
            <h3>Descripcion producto</h3>
            <h6>
              {report?.from_artisan_production_order_item?.product
                ?.description || "-"}
            </h6>
          </div>
          <div></div>
        </div>
        <div className="divisor"></div>

        <div className="detalle-reporte-dinero">
          <div className="reporte-dinero-element-wrapper">
            <h3>Cantidad</h3>
            <h6>{report.quantity}</h6>
          </div>
          <div className="reporte-dinero-element-wrapper">
            <h3>Calidad</h3>
            <h6>{report?.price_list_batch?.batch?.name}</h6>
          </div>
          {/* <div className="reporte-dinero-element-wrapper">
            <h3>$ Unitario</h3>
            <h6>{report.amounts?.total}</h6>
          </div> */}
          <div className="reporte-dinero-element-wrapper">
            <h3>$ Artesana</h3>
            <h6>{report.amounts?.artisan}</h6>
          </div>
          <div className="reporte-dinero-element-wrapper">
            <h3>$ Coordinadora</h3>
            <h6>{report.amounts?.work_group}</h6>
          </div>
          <div className="reporte-dinero-element-wrapper">
            <h3>$ COMAR</h3>
            <h6>{report.amounts?.community}</h6>
          </div>
          <div className="reporte-dinero-element-wrapper">
            <h3>$ Total</h3>
            <h6>{report.amounts?.total}</h6>
          </div>
        </div>

        <div className="detalle-reportes-row">
          <div>
            <h3>N Pedido Artesana</h3>
            <h6>
              #
              {report?.from_artisan_production_order_item
                ?.artisan_production_order || "-"}
            </h6>
          </div>
          <div>
            <h3>N Pedido Grupo</h3>
            <h6>
              #
              {report?.from_artisan_production_order_item
                ?.work_group_production_order || "-"}
            </h6>
          </div>
          <div>
            <h3>N Pedido Origen</h3>
            <h6>
              #{report?.from_artisan_production_order_item?.sell_order || "-"}
            </h6>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export { ReportModal };
