import { Button } from "UI/atoms";
const Topbar = ({ onSetIsOpenDownloadFilesModalOpen, association }) => {
  return (
    <ul
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginTop: "20px",
      }}
    >
      <li>
        <h1 style={{ fontWeight: 700, fontSize: "24px", lineHeight: "29px" }}>
          {association}
        </h1>
      </li>
      <li>
        <Button
          background="#339589"
          color="white"
          width="220px"
          height="44px"
          fontSize="1.5em"
          className="action-btn"
          onClick={() => onSetIsOpenDownloadFilesModalOpen(true)}
        >
          Descargar archivos
        </Button>
      </li>
    </ul>
  );
};

export { Topbar };
