import toast, { Toaster } from "react-hot-toast";
import { useState, useEffect } from "react";

const useNotifierFactory = () => {
  const [timer, setTimer] = useState(4);
  const toastType = (type, text, id, duration, styles = {}) =>
    toast[type](text, {
      style: { fontSize: "18px", ...styles },
      duration,
      id: id,
    });

  useEffect(() => {
    if (timer) setTimer((prevState) => prevState - 1);
    if (timer === 0) toast.dismiss();
  }, [timer]);

  return {
    toastType: toastType,
    toaster: <Toaster />,
  };
};

export { useNotifierFactory };
