import { Button } from "UI/atoms";
import { CaretLeft } from "shared/assets/svgs/CaretLeft";
import { CaretRight } from "shared/assets/svgs/CaretRight";
import { DoubleCaretLeft } from "shared/assets/svgs/DoubleCaretLeft";
import { DoubleCaretRight } from "shared/assets/svgs/DoubleCaretRight";
import { ThreeDots } from "shared/assets/svgs/ThreeDots";
import { usePaginator } from "hooks/usePaginator";

const Paginator = ({ totalItems, currentPage, setCurrentPage }) => {
  const {
    hasPaginatorMoreThanThreePages,
    pageSelected,
    isCurrentPageTheLastPage,
    totalPages,
    hasNextPage,
    hasPreviousPage,
    visiblePages,
    hasResults,
    handleNextPage,
    handlePreviousPage,
    handleFirstPage,
    handleLastPage,
    handlePageClick,
  } = usePaginator({ totalItems, currentPage, setCurrentPage });

  return (
    <div>
      {hasResults ? (
        <div
          style={{
            display: "flex",
            width: "250px",
            height: "30px",
            background: "white",
            justifyContent: "space-around",
            borderRadius: "20px",
            marginTop: "10px",
          }}
        >
          <Button
            disabled={!hasPreviousPage}
            width="auto"
            onClick={() => handleFirstPage()}
          >
            <>
              <DoubleCaretLeft />
            </>
          </Button>

          <Button
            disabled={!hasPreviousPage}
            width="auto"
            onClick={() => handlePreviousPage()}
          >
            <CaretLeft />
          </Button>

          {visiblePages.map((pageNumber) => (
            <Button
              onClick={() => handlePageClick(pageNumber)}
              width="auto"
              fontSize={pageNumber === pageSelected ? "20px" : "14px"}
            >
              {pageNumber}
            </Button>
          ))}

          {hasPaginatorMoreThanThreePages && !isCurrentPageTheLastPage ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {currentPage !== "last" ? <ThreeDots /> : null}

              <Button
                width="auto"
                fontSize={"14px"}
                onClick={() => handleLastPage("last")}
              >
                {totalPages}
              </Button>
            </div>
          ) : null}

          <Button
            disabled={!hasNextPage}
            width="auto"
            onClick={() => handleNextPage()}
          >
            <CaretRight />
          </Button>

          <Button
            disabled={!hasNextPage}
            width="auto"
            onClick={() => handleLastPage("last")}
          >
            <DoubleCaretRight />
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export { Paginator };
