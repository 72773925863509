import { useNavigate, useParams } from "react-router-dom";
import { Button } from "UI/atoms";
import noProd from "shared/images/no-product.svg";
import Pencil from "shared/images/PencilLineGreen.svg";
import { isValidObject } from "utils/isValidObject";
import { PencilEditCircle } from "shared/assets/svgs/PencilEditCircle";
import { Clon } from "shared/assets/svgs/Clon";

const ComarProductRow = ({ product }) => {
  const navigate = useNavigate();
  const { name } = useParams();

  const nav = (productId) => {
    name === "comar"
      ? navigate(`${productId}/edit-product`)
      : navigate(`${productId}/description-product`);
  };
  return (
    <tr>
      <td>
        <figure>
          <img
            src={
              product?.pictures?.length > 0 ? product.pictures[0].file : noProd
            }
            alt=""
            style={{
              width: "36px",
              height: "36px",
            }}
          />
        </figure>
      </td>
      <td>{product.code}</td>
      <td>{product.name}</td>
      <td>
        {isValidObject(product.material)
          ? product?.material.name
          : "Sin materiales"}
      </td>
      <td>
        {product.full_description ||
          product.short_description ||
          "No hay descripción"}
      </td>
      <td>
        <Button
          width="auto"
          height="auto"
          borderRadius="0"
          background="transparent"
          className="edit"
          onClick={() => nav(product?.id)}
        >
          <PencilEditCircle />
        </Button>
      </td>
    </tr>
  );
};

export { ComarProductRow };
