const TableHeaders = () => {
  return (
    <tbody>
      <tr>
        <th>Fecha</th>
        <th>Hora</th>
        <th>Código reporte</th>
        <th>Asociación</th>
        <th>Detalles</th>
      </tr>
    </tbody>
  );
};

export { TableHeaders };
