import Modal from 'styled-react-modal'

const StyledModal = Modal.styled`
  position: relative;
  width: ${props => props.width};
  height: ${props => props.height};
  display: ${props => props.display};
  padding: ${props => props.padding};
  align-items: ${props => props.alignItems};
  justify-content: ${props => props.justifyCont};
  background-color: ${props => props.background || 'white'};
  border-radius: ${props => props.borderRadius};
`

export {StyledModal}