import React from "react";

const PricesList = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8125 17H28.6874"
        stroke="#413A44"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8125 8.5H28.6874"
        stroke="#413A44"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8125 25.5H28.6875"
        stroke="#413A44"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.86523 6.375V14.875"
        stroke="#413A44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.15385 8.99033C9.15385 8.77567 9.11157 8.56311 9.02942 8.36479C8.94727 8.16647 8.82687 7.98627 8.67508 7.83448C8.52329 7.68269 8.34309 7.56229 8.14477 7.48014C7.94645 7.39799 7.73389 7.35571 7.51923 7.35571H6.04808C5.61455 7.35571 5.19878 7.52793 4.89223 7.83448C4.58568 8.14103 4.41346 8.5568 4.41346 8.99033C4.41346 9.42386 4.58568 9.83963 4.89223 10.1462C5.19878 10.4527 5.61455 10.6249 6.04808 10.6249H7.84615C8.27968 10.6249 8.69545 10.7972 9.002 11.1037C9.30855 11.4103 9.48077 11.826 9.48077 12.2596C9.48077 12.6931 9.30855 13.1089 9.002 13.4154C8.69545 13.722 8.27968 13.8942 7.84615 13.8942H5.88462C5.45109 13.8942 5.03532 13.722 4.72877 13.4154C4.42222 13.1089 4.25 12.6931 4.25 12.2596"
        stroke="#413A44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.86523 19.125V27.625"
        stroke="#413A44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.15385 21.7403C9.15385 21.5257 9.11157 21.3131 9.02942 21.1148C8.94727 20.9165 8.82687 20.7363 8.67508 20.5845C8.52329 20.4327 8.34309 20.3123 8.14477 20.2301C7.94645 20.148 7.73389 20.1057 7.51923 20.1057H6.04808C5.61455 20.1057 5.19878 20.2779 4.89223 20.5845C4.58568 20.891 4.41346 21.3068 4.41346 21.7403C4.41346 22.1739 4.58568 22.5896 4.89223 22.8962C5.19878 23.2027 5.61455 23.3749 6.04808 23.3749H7.84615C8.27968 23.3749 8.69545 23.5472 9.002 23.8537C9.30855 24.1603 9.48077 24.576 9.48077 25.0096C9.48077 25.4431 9.30855 25.8589 9.002 26.1654C8.69545 26.472 8.27968 26.6442 7.84615 26.6442H5.88462C5.45109 26.6442 5.03532 26.472 4.72877 26.1654C4.42222 25.8589 4.25 25.4431 4.25 25.0096"
        stroke="#413A44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { PricesList };
