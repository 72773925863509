import { DownloadCloud } from "shared/assets/svgs/DownloadCloud";

const DownloadFileRow = ({ file = {}}) => {
  function extractNameFromURL(url) {
    // Extract "Emilio Jose Lopez" from either:
    // https://app.chaco.org/media/reports/onanagaelpi/004563/coord_emilio-jose-lopez.xlsx
    // http://localhost:8000/media/reports/wachidiyaga/000002/coord_emilio-jose-lopez_l87Yfd4.xlsx
    const nameInURLRegex = /\/reports\/([^/]+)\/\d{6}\/coord_([^_]+)(_[A-Za-z0-9]+)?\.xlsx/;
    const match = url.match(nameInURLRegex);
    const matchedAsExpected = match && match.length === 4;
    if (matchedAsExpected) {
        const nameParts = match[2].split('-');
        return nameParts.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');
    } else {
        return '';
    }
}
  const fileLabel = (file) => {
    switch (file.type)  {
      case 'coord': {
        return `Resumen coordinadora ${extractNameFromURL(file.file)}`;
      }
      case 'etiquetas': return 'Etiquetas';
      case 'listado': return 'Listado';
      case 'remito': return 'Remito';
      case 'trans': return 'Transferencias';
    }
  }
  return (
    <tbody>
      <tr className="entregas-data-row">
        <td>
          {fileLabel(file)}
        </td>
        <td>
            <a
              href={file.file}
              download
              width="auto"
              style={{ marginRight: "5px" }}
            >
              <DownloadCloud />
            </a>
        </td>
      </tr>
    </tbody>
  );
};
export { DownloadFileRow };
